import { FaStar } from "react-icons/fa";

export function ReviewCard(props) {
  const { nStars, starColor, review, author } = props;
  return (
    <div className="flex flex-col items-center">
      <div className="flex gap-x-1">
        {[...Array(nStars)].map((val, index) => (
          <div key={index} data-testid="reviewcard-fastar-test">
            <FaStar
              style={{
                color: starColor,
              }}
              className="text-lg"
            />
          </div>
        ))}
      </div>
      <p className="mt-4 max-w-4xl text-2xl lg:text-3xl leading-tight text-center font-semibold">
        {review}
      </p>
      <p className="mt-2 text-stone-600 text-lg"> - {author} </p>
    </div>
  );
}
