

const CardContainer = (props) => {
    const { className } = props;
    return (
      <div className={`w-full rounded-md bg-white ${className}`}>
        {props.children}
      </div>
    );
  };


export default CardContainer;
