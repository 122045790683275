import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import { ConfBookingListView, ConfBookingDeleteView } from '../../Api/Booking';
import { BookingConfigSelect } from './BookingConfigSelect';
import { BookingNewConfig } from './BookingNewConfig';
import ConfirmationModal from '../ConfirmationModal';

function BookingConfigTable(props) {
  const { id, token } = props;
  const [bookingData, setBookingData] = useState([]);
  const [selectedBooking, setSelectedBooking] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false); 
  const [bookingToDelete, setBookingToDelete] = useState(null); 

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleBookingClick = async (selectedBooking) => {
    setSelectedBooking(selectedBooking);

    try {
      const result = await ConfBookingListView(id, token);
      setBookingData(result);
    } catch (error) {
      console.error('Error al obtener datos de ConfBooking 1:', error);
    }
  };

  const handleBookingDataUpdate = async () => {
    try {
      const result = await ConfBookingListView(id, token);
      setBookingData(result);
    } catch (error) {
      console.error('Error al obtener datos de ConfBooking 2:', error);
    }
  };

  const handleDelete = async (bookingId) => {
    try {
      await ConfBookingDeleteView(bookingId, token);
      handleBookingDataUpdate();
      setConfirmationModalOpen(false); 
    } catch (error) {
      console.error('Error al eliminar la reserva:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await ConfBookingListView(id, token);
        setBookingData(result);
      } catch (error) {
        console.error('Error al obtener datos de ConfBooking 3:', error);
      }
    };

    fetchData();
  }, [id, token]);

  const buttonClasses = "text-white py-3 px-2 rounded-lg flex flex-col items-center";

  return (
    <>
      <div className="flex flex-wrap gap-2 bg-white items-center w-full rounded-lg shadow-md mb-2 p-2 mx-auto">
        <button className="bg-emerald-500 text-white py-6 rounded-lg justify-center px-4" onClick={openModal}>
          <p className="text-base font-semibold">+ Nuevo </p>
        </button>
        <BookingNewConfig
          isOpen={isModalOpen}
          onClose={closeModal}
          id={id}
          token={token}
          updateBookingData={handleBookingDataUpdate}
        />

        {bookingData.map((booking, index) => (
          <button
          key={index}
          className={`
          ${booking.status_conf === 0 ? 'bg-red-500' : ''}
          ${booking.hora_inicio !== booking.hora_fin && booking.status_conf >= 1 ? 'bg-blue-400' : ''}
          ${booking.hora_inicio == booking.hora_fin && booking.hora_inicio_tarde !== booking.hora_fin_tarde && booking.status_conf >= 1 ? 'bg-orange-400' : ''}
          ${booking.hora_inicio == booking.hora_fin && booking.hora_inicio_tarde == booking.hora_fin_tarde && booking.hora_inicio_noche !== booking.hora_fin_noche && booking.status_conf >= 1 ? 'bg-purple-500' : ''}
          ${booking.hora_inicio == booking.hora_fin &&  booking.hora_inicio_tarde== booking.hora_fin_tarde && booking.hora_inicio_noche== booking.hora_fin_noche && booking.status_conf >= 1 ? 'bg-emerald-500' : ''}
          ${buttonClasses}
          `}
          onClick={() => handleBookingClick(booking)}
          >

            <div className="relative mb-3">
              <FaTimes
                className="absolute bottom-1/5 left-12 cursor-pointer text-white hover:text-neutral-700 mr-1"
                onClick={(event) => {
                  event.stopPropagation();
                  setBookingToDelete(booking); 
                  setConfirmationModalOpen(true); 
                }}
              />
            </div>

            <div className="flex mb-3">
            {booking.holiday.replace(/\[|\]/g, '').split(',').map((day, dayIndex) => (
              <p
                key={dayIndex}
                className={`mx-1 text-xs font-semibold ${
                  day === 'false' ? 'text-neutral-100' : 'text-neutral-800 opacity-50'
                }`}>
                {['D', 'L', 'M', 'M', 'J', 'V', 'S'][dayIndex]} 
              </p>
            ))}
          </div>


            <p className="text-sm font-semibold overflow-hidden whitespace-nowrap overflow-ellipsis">
              {booking.btn}
            </p>
          </button>
        ))}
      </div>

      <BookingConfigSelect
        selectedBooking={selectedBooking}
        id={id}
        setBookingData={handleBookingDataUpdate}
      />

      {/* Modal de confirmación */}
      <ConfirmationModal
        isOpen={confirmationModalOpen}
        message="¿Estás seguro de que deseas eliminar esta reserva?"
        onConfirm={() => handleDelete(bookingToDelete.id)}
        onClose={() => setConfirmationModalOpen(false)}
      />
    </>
  );
}

export { BookingConfigTable };
