import axios from 'axios';
import { LOCALHOST_DOMAIN, DOMAIN } from "../../../config/Constant";
import { useState, useEffect } from 'react';

export const useAllTransactions = (token, status = null) => {
    const [transactions, setTransactions] = useState([]);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      const fetchTransactions = async () => {
        try {
          let url = `${DOMAIN}pay/transactions/`;

          if (status !== null && status !== '0') {
            url += `?status=${status}`;
          }
  
          const response = await axios.get(url, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `JWT ${token}`,
            },
          });
  
          if (!response.data.success) {
            throw new Error("Error fetching transactions");
          }
  
          setTransactions(response.data.data);
        } catch (error) {
          setError(error.message);
        }
      };
  
      fetchTransactions();
    }, [token, status]);
  
    return { transactions, error };
};





