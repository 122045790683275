import axios from 'axios';
import { DOMAIN, LOCALHOST_DOMAIN } from "../../../config/Constant";

export const getAllProfilesForUser = async (userProfileId, token) => {
  try {
    const response = await axios.get(`${DOMAIN}profile/log/${userProfileId}/`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};
