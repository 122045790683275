import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./loginSlice";
import bookingReducer from './bookingSlice';

export const store = configureStore({
  reducer: {
    login: loginReducer,
    booking: bookingReducer,
  },
});
