import { generateRandomKey } from "../../../utils/generateRandomKey";

function convertDataToBarHeight(data, max) {
  return data.map((val) => val / max);
}

function getRangesData(data) {
  const max = Math.max(...data);
  let digits = Math.floor(Math.log10(max)) + 1;

  if (digits <= 2) {
    digits = 3;
  }
  const base = Math.pow(10, digits - 2);
  const newMax = max + (base - (max % base));
  const newMin = 0;
  return [newMin, newMax];
}

function getStepSize(max, min, markers) {
  const step = Math.round(max - min) / (markers - 1);

  const multiple = 5;
  const niceStep = step + (multiple - (step % multiple));
  return niceStep;
}

function Rectangle(props) {
  const { value, barColor } = props;

  return (
    <div
      style={{
        backgroundColor: barColor,
        width: "24px",
        height: `${value * 100}%`,
      }}
    ></div>
  );
}

function BarChartYAxis(props) {
  const { max, step, markers, gridColor } = props;

  return (
    <div className="flex flex-col h-full">
      {[...Array(markers)].map((_, index) => (
        <div
          key={generateRandomKey()}
          className="flex items-end justify-end border-r-2"
          style={{
            height: `${100 / markers}%`,
            borderRightColor: index === 0 ? "transparent" : gridColor,
          }}
        >
          <div className="text-sm translate-y-1/2">{max - step * index}</div>
          <div
            className="translate-y-1/2 translate-x-[2px] ml-[2px] w-2 h-[2px]"
            style={{ backgroundColor: gridColor }}
          ></div>
        </div>
      ))}
    </div>
  );
}

export function BarChart(props) {
  /*
  - Descripción de como funciona esta gráfica 

  data: Lista de objetos { title, value, color }
  
  [min, max] = El valor máximo y 
  
  */
  const { className, gridColor } = props;
  let { data } = props;

  if (data.length === 0) {
    return <div></div>;
  }
  let dataValues = data.map((d) => d.value);
  let dataColors = data.map((d) => d.color);

  const markers = 6;
  const [min, max] = getRangesData(dataValues);
  const stepSize = getStepSize(max, min, markers);
  const newMax = stepSize * (markers - 1);
  const dataHeight = convertDataToBarHeight(dataValues, newMax);

  return (
    <div className={`${className} flex items-end`}>
      <BarChartYAxis
        max={newMax}
        step={stepSize}
        markers={markers}
        gridColor={gridColor}
      />
      <div
        className="pl-2 pr-2 border-b-2 gap-4 translate-y-[1px] flex w-full justify-between items-end"
        style={{ height: `${100 - 100 / markers}%`, borderColor: gridColor }}
      >
        {dataHeight.map((value, index) => (
          <Rectangle
            key={generateRandomKey()}
            value={value}
            barColor={dataColors[index]}
          />
        ))}
      </div>
    </div>
  );
}
