import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import { createNewModule, updateModuleById } from '../Api/Modules';

function ModulesForm({ isOpen, onClose, moduleData, token, reloadModules }) {
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        type: '',
        status: 1
    });

    useEffect(() => {
        if (moduleData) {
            setFormData({
                name: moduleData.name,
                description: moduleData.description,
                type: moduleData.type,
                status: moduleData.status ? 1 : 0
            });
        }
    }, [moduleData]);

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        const newValue = type === 'checkbox' ? (checked ? 1 : 0) : value;
        setFormData(prevState => ({
            ...prevState,
            [name]: newValue
        }));
    };

    const commonInputClass = "mt-1 block w-full shadow-lg border-neutral-400 sm:text-sm border rounded-sm p-2";
    const buttonClass = "px-4 py-2 border border-transparent rounded-md text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2";
    const submitButtonClass = "py-1 px-4 bg-emerald-500 hover:bg-emerald-600 focus:ring-emerald-500";
    const cancelButtonClass = "mr-2 py-1 px-4 bg-gray-400 hover:bg-gray-500 focus:ring-gray-500";

    const handleSubmit = async (event) => {
        event.preventDefault();
        const now = new Date();
        const createdAt = now.toISOString();
        const updatedAt = now.toISOString();
        const newData = {
            ...formData,
            created_at: createdAt
        };
    
        try {
            let response;
            if (moduleData) {
                response = await updateModuleById(token, moduleData.id, newData);
            } else {
                response = await createNewModule(newData, token);
            }
    
            onClose();
            reloadModules();
            setFormData({
                name: '',
                description: '',
                type: '',
                status: 1
            });
        } catch (error) {
            console.error('Error al enviar los datos:', error);
        }
    };
    
    return (
        <>
            {isOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-neutral-800 bg-opacity-10">
                    <div className="bg-white max-w-md p-6 rounded-md shadow-lg">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-lg font-semibold">{moduleData ? 'Editar Módulo' : 'Crear Módulo'}</h2>
                            <FaTimes onClick={onClose} className="text-neutral-800 cursor-pointer w-3 h-3" />
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                    Nombre
                                </label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    className={commonInputClass}
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                                    Descripción
                                </label>
                                <textarea
                                    id="description"
                                    name="description"
                                    value={formData.description}
                                    onChange={handleChange}
                                    rows={3}
                                    className={commonInputClass}
                                    required
                                />
                            </div>
                            <div className="flex">
                                <div className="flex-1 mr-4 relative">
                                    <label htmlFor="type" className="block text-sm font-medium text-gray-700">
                                        Tipo
                                    </label>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            id="type"
                                            name="type"
                                            value={formData.type}
                                            onChange={handleChange}
                                            className={`${commonInputClass} pr-10`}
                                            required
                                        />
                                        
                                    </div>
                                </div>
                            </div>

                            <div className="mb-4">
                                <div className="flex items-center">
                                    <input
                                        id="status"
                                        name="status"
                                        type="checkbox"
                                        checked={formData.status === 1} // Verificar si status es 1 para marcar el checkbox
                                        onChange={handleChange}
                                        className="focus:ring-emerald-500 h-4 w-4 text-emerald-600 border-gray-300 rounded"
                                    />
                                    <label htmlFor="status" className="ml-2 block text-sm text-gray-900">
                                        Estado
                                    </label>
                                </div>
                            </div>
                            <div className="flex justify-end">
                                <button
                                    type="button"
                                    onClick={onClose}
                                    className={`${buttonClass} ${cancelButtonClass}`}
                                >
                                    Cancelar
                                </button>
                                <button
                                    type="submit"
                                    className={`${buttonClass} ${submitButtonClass}`}
                                >
                                    {moduleData ? 'Actualizar' : 'Crear'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </>
    );
}

export default ModulesForm;
