import React from 'react'

function DiscountSearch() {
  return (
    <div className="flex flex-wrap gap-x-6 gap-y-2 items-end">

              <div className="w-full sm:max-w-[24rem]">
                <label className="text-sm text-gray-700"> Buscar </label>
                <div className="relative">
                  <input
                    className="pl-6 pr-4 py-1 placeholder-stone-400 outline-none rounded-md w-full sm:min-w-[24rem] border border-gray-300 focus:border-gray-600 bg-white"
                    type="text"
                    placeholder="Buscar"
                    //onChange={(e) => optimizedFn(e.target.value)}
                  />
                  {/* <AiOutlineSearch className="absolute left-1 top-1/2 -translate-y-1/2 cursor-pointer  text-stone-400" /> */}
                </div>
              </div>
              <div className="flex flex-col">
                <label className="text-sm text-gray-700"> Tipo de descuento </label>
                <select className="px-2 py-[6px] rounded-md bg-white border border-gray-300 foc focus:border-gray-600">
                  <option value="todos"> Todos </option>
                  <option value="admin"> porcentual </option>
                  <option value="users"> decimal </option>
                </select>
              </div>
              <div className="flex flex-col">
                <label className="text-sm text-gray-700"> Estado </label>
                <select className="px-2 py-[6px] rounded-md bg-white border border-gray-300 foc focus:border-gray-600">
                  <option value="todos"> --- </option>
                  <option value="0"> Activo </option>
                  <option value="1"> Inactivo </option>
                </select>
              </div>
              
            </div>
  )
}

export  {DiscountSearch}
