import React, { useState, useEffect } from "react";

const DateTimeButtons = ({ id, setSelectedDate, configDetails, formError, setFormError, bookings, setSelectedDateTime   }) => {

  const [selectedButtonIndex, setSelectedButtonIndex] = useState(null);
  const [dateButtons, setDateButtons] = useState([]);
  const dateClassname = "bg-neutral-800 hover:bg-neutral-700 text-white text-ls py-2 px-4 rounded";
  const [customDateInputValue, setCustomDateInputValue] = useState("");
  const [newFormattedDateTime, setNewFormattedDateTime]=useState("");

  useEffect(() => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    const next6Days = Array.from({ length: 7 }, (_, index) => {
      const currentDate = new Date(tomorrow);
      currentDate.setDate(tomorrow.getDate() + index -1);
      return currentDate;
    });

    const formattedDates = next6Days.map(date => {
      const options = {
        weekday: "short",
        month: "short",
        day: "numeric"
      };
      return {
        date: date,
        formattedDate: date.toLocaleDateString("es-ES", options),
        isToday: date.getDate() === today.getDate() // Compara si la fecha es hoy
      };
    });

    setDateButtons(formattedDates);
  }, []);

  const holidayString = configDetails.holiday || "[]";
  const holidayArray = JSON.parse(holidayString);

  const isDayDisabled = (dayIndex) => {
    const next6Days = Array.from({ length: 7 }, (_, index) => {
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + index);
      return currentDate;
    });

    if (holidayArray.length > 0) {
      const currentDay = next6Days[dayIndex].getDay();
      return holidayArray[currentDay];
    }
    return false;
  };

  const generateTimeArray = (startHour, endHour, interval) => {
    const timeArray = [];
    let currentHour = new Date(`1970-01-01T${startHour}`).getTime(); // Convertir la hora inicial en milisegundos
    
    while (currentHour <= new Date(`1970-01-01T${endHour}`).getTime()) { // Mientras la hora actual sea menor o igual que la hora final
      timeArray.push(new Date(currentHour).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })); // Agregar la hora al array en formato hh:mm
      currentHour += interval * 60000; // Sumar el intervalo en milisegundos (intervalo está en minutos)
    }
    
    return timeArray;
  };

const startHour = configDetails.hora_inicio;
const endHour = configDetails.hora_fin_noche; 
const interval = configDetails.time_bet_booking; 
const normalHoursArray = generateTimeArray(startHour, configDetails.hora_fin, interval);
const tardeHoursArray = generateTimeArray(configDetails.hora_inicio_tarde, configDetails.hora_fin_tarde, interval);
const nocheHoursArray = generateTimeArray(configDetails.hora_inicio_noche, configDetails.hora_fin_noche, interval);

const handleDayButtonClick = (formattedDate) => {
  const selectedIndex = dateButtons.findIndex(button => button.formattedDate === formattedDate);
  const isDisabled = isDayDisabled(selectedIndex);
  
  
  if (!isDisabled) {
    const dateParts = formattedDate.split(', ')[1].split(' '); // Separar en ["20", "mar"]
    const dayOfMonth = dateParts[0]; // Obtener el día del mes
    const month = dateParts[1]; // Obtener el mes
    const monthsMap = {
      ene: '01',
      feb: '02',
      mar: '03',
      abr: '04',
      may: '05',
      jun: '06',
      jul: '07',
      ago: '08',
      sep: '09',
      oct: '10',
      nov: '11',
      dic: '12'
    };
    const monthNumber = monthsMap[month.toLowerCase()]; // Obtener el número del mes
    const currentYear = new Date().getFullYear();
    const selectedDate = new Date(currentYear, monthNumber - 1, dayOfMonth); // Crear objeto Date con el año, mes y día seleccionados
    
    // Eliminar la verificación si la fecha seleccionada no es anterior a hoy

    const formattedDateString = selectedDate.toISOString().split('T')[0]; // Formatear la fecha seleccionada
    document.getElementById('customDateInput').value = formattedDateString; // Actualizar el valor del input de fecha personalizada
    setCustomDateInputValue(formattedDateString); // Actualizar el estado del valor de la fecha personalizada
    setFormError(null); // Limpiar el mensaje de error
  } else {
    setFormError("El día seleccionado está deshabilitado."); // Establecer el mensaje de error
  }
};






const handleDateTimeInputChange = (event) => {
  const customDateInputValue = event.target.form.customDateInput.value;
  const customTimeInputValue = event.target.form.customTimeInput.value;
  const mergedDateTime = customDateInputValue + " " + customTimeInputValue;
  const formattedDateTime = new Date(mergedDateTime);
  formattedDateTime.setHours(formattedDateTime.getHours() );
  const formattedDateTime01 = new Date(mergedDateTime);
  formattedDateTime01.setHours(formattedDateTime01.getHours() -4);
  const options = {
    year: "numeric",
    month: "long",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZone: "America/La_Paz" // Ajustar según la zona horaria deseada
  };
  const formattedDateString = formattedDateTime.toLocaleString("es-ES", options);
  const formattedDateTimeUTC = new Date(formattedDateTime.getTime() - (formattedDateTime.getTimezoneOffset() * 60000));
  const formattedDateTimeISO = formattedDateTimeUTC.toISOString().slice(0, -5) + "Z";
  const newFormattedDateTime = formattedDateTimeISO;
  setNewFormattedDateTime(formattedDateTimeISO);
  setSelectedDateTime(formattedDateTimeISO);
  
  const matchingBookingsCount = bookings.reduce((count, booking) => {
    const bookingDate = new Date(booking.date);
    if (
      bookingDate.getTime() === formattedDateTime01.getTime() && // Comprobar si las fechas son iguales en milisegundos
      (booking.status_booking === 0 || booking.status_booking === 1)
    ) {
      count++;
    }
    return count;
  }, 0);

  
  if (matchingBookingsCount >= configDetails.max_reservas) {
    setFormError("Se ha alcanzado el límite máximo de reservas para este horario.");
  } else {
    setFormError(null);
  }
  document.getElementById('mergedDate').value = formattedDateString;
};

const handleCustomDateInputChange = (event) => {
  const selectedDate = event.target.value;
  const selectedDateObject = new Date(selectedDate);
  const currentDate = new Date();
  
  if (selectedDateObject < currentDate) {
    setFormError("No se puede seleccionar una fecha anterior a hoy.");
    return;
  }
  
  const dayIndex = selectedDateObject.getDay(); 
  if (isDayDisabled(dayIndex)) {
    setFormError("El día seleccionado está deshabilitado.");
    return; 
  }
  
  setCustomDateInputValue(selectedDate); 
  setFormError(null); 
  handleDateTimeInputChange(event);
};




const handleCustomTimeInputChange = (event) => {
  handleDateTimeInputChange(event); // Llamar a la función común para manejar el cambio de fecha y hora
};

  return (
    <div>
      <div id="fecha" className="flex justify-start space-x-1 mb-1 mt-1 sm:space-x-1 md:space-x-1 overflow-x-auto">
        {dateButtons.map((button, index) => (
          <div
            key={index}
            className={`${dateClassname} ${selectedButtonIndex === index ? "bg-neutral-600" : ""} ${
              button.isToday ? "text-bold" : ""
            } sm:text-sm md:text-sm`}
            style={{
              cursor: isDayDisabled(index) ? "not-allowed" : "pointer",
              opacity: isDayDisabled(index) ? 0.5 : 1
            }}
            onClick={() => handleDayButtonClick(button.formattedDate)}
          >
            <div className="text-xs sm:text-sm">{button.formattedDate}</div>
          </div>
        ))}
      </div>

      <span className="text-gray-700 text-sm mt-3">Personalizar Fecha y hora:</span>      
        <div className="flex justify-center">
            <div className="mr-4">
              <label className="block mb-2">
                <input
                  id="customDateInput"
                  className="form-input mt-1 block w-full border  bg-white p-2 border-neutral-300 text-center text-base py-2"
                  type="date"
                  name="customDateInput"
                  onChange={handleCustomDateInputChange}
                />
              </label>
            </div>
            <div>

            <label className="block mb-2">  
            <select
              className="form-select mt-1 block w-full border text-base bg-white p-2 border-neutral-300 py-2"
              name="customTimeInput"
              onChange={handleCustomTimeInputChange}
            >
  <option value="" disabled selected>
    Selecciona una hora
  </option>
  {configDetails.hora_inicio !== configDetails.hora_fin && (
    <>
  {normalHoursArray.map((time, index) => (
    <option key={index} value={time}>
      {time}
    </option>
  ))}
 </>
  )}
{configDetails.hora_inicio_tarde !== configDetails.hora_fin_tarde && (
    <>
  {tardeHoursArray.map((time, index) => (
    <option key={index} value={time}>
      {time} (Tarde)
    </option>
  ))}
   </>
  )}
  {configDetails.hora_inicio_noche !== configDetails.hora_fin_noche && (
    <>
  {nocheHoursArray.map((time, index) => (
    <option key={index} value={time}>
      {time} (Noche)
    </option>
  ))}
  </>
  )}
            </select>
            </label>
            </div>
        </div>
        <div className="mb-4">
         
          {formError && <span className="text-red-500">{formError}</span>}
          <input
            type="text"
            id="mergedDate"
            name="mergedDate"
            className=" p-2  w-full text-gray-700 text-sm mt-3 text-center"
            readOnly
          />
        </div>
    </div>
  );
};

export { DateTimeButtons };
