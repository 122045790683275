import React, { useState, useRef } from "react";
import { HiTrash } from "react-icons/hi";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import axios from "axios";

import { DOMAIN, IMAGE_DOMAIN } from "../../../../config/Constant";
import { compressImage, uploadImage } from "../../../../utils/uploadImages";
import { overrideUndefinedImage } from "../../../../utils/overrideUndefinedImage";
import OverlayModal from "../../../../components/Modals/OverlayModal";

import LoadingButton from "../../../../components/LoadingButton";
import LoadingSpinnerV2 from "../../../../components/LoadingSpinnerV2";

async function editLinkImage(
  token,
  title,
  socialMediaId,
  imageData,
  imageContentData
) {
  // Utilizo axios para enviar el formData
  const url = DOMAIN + "profile/custom_social_media/" + socialMediaId;

  const data = new FormData();

  if (imageData) {
    data.append("image", imageData);
  }
  if (imageContentData) {
    data.append("imageQR", imageContentData);
  }
  data.append("title", title);
  data.append("type", "image");
  const response = await axios.put(url, data, {
    headers: {
      Authorization: `JWT ${token}`,
    },
  });

  return response;
}

async function deleteCustomSocialMedia(token, linkId) {
  // TODO: Refactorizar en una carpeta de /Services o /Repositories
  // Todavía no existe en el backend

  const response = await fetch(
    DOMAIN + "profile/custom_social_media/" + linkId,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
    }
  );

  return response;
}

const EditImageTypeModal = (props) => {
  // Permite editar iconos personalizados (custom_social_media)
  // setShowModal:      Permite abrir o cerrar el modal
  // setSocialMedia:    Permite actualizar la lista de enlaces, solo el que se esta editando (titulo, enlace y foto)
  // link:              Información del enlace
  // imageFormData:     Estructura para enviar la imagen a la API
  // imageSource:       La imagen que se muestra dentro del modal

  const { setShowModal, setSocialMedia, deleteSocialMedia, link } = props;
  const [imageSource, setImageSource] = useState(
    `${IMAGE_DOMAIN}${link.image}`
  );

  const [imageFormData, setImageFormData] = useState(null);
  const [imageSourceContent, setImageSourceContent] = useState(
    `${IMAGE_DOMAIN}${link.url}`
  );
  const [imageFormDataContent, setImageFormDataContent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const token = useSelector((state) => state.login.token);

  const { register, handleSubmit, formState } = useForm({
    defaultValues: link,
  });

  const [deleteLoading, setDeleteLoading] = useState(false);
  const fileRefIcon = useRef(null);
  const fileRefContent = useRef(null);

  // TODO: Utilizar Form para validar datos de forma correcta
  //const methods = useForm()

  function closeModal() {
    // TODO: Agregar confirmación al salir
    //¿Estás seguro que deseas salir?
    setShowModal(false);
  }

  function linkHasBeenModified() {
    return formState.isDirty;
  }

  async function handleEditCustomSocialMedia(data) {
    //TODO: Mejorar manejo de errores con un Modal?

    setIsLoading(true);
    const linkId = link.id;
    const title = data.title;

    editLinkImage(token, title, linkId, imageFormData, imageFormDataContent)
      .then((responseImg) => {
        setSocialMedia(responseImg.data.data);
        closeModal();
      })
      .catch((error) => {
        console.error(error);
        window.alert(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function handleImageUpload(
    event,
    setImageSource,
    setImageFormData,
    option = ""
  ) {
    event.preventDefault();
    const files = event.target.files;
    uploadImage(files, async (reader) => {
      setImageSource(reader.result);

      let maxSizeMB = undefined;
      let maxWidthOrHeight = undefined;
      if (option === "compress-less") {
        maxSizeMB = 1;
        maxWidthOrHeight = 2000;
      }
      const file = await compressImage(files, maxSizeMB, maxWidthOrHeight);
      setImageFormData(file);
    });
  }

  function handleOpenFilePicker(fileRef) {
    if (fileRef.current) {
      fileRef.current.click();
    }
  }

  async function handleDeleteCustomSocialMedia() {
    setDeleteLoading(true);
    const responseFuture = deleteCustomSocialMedia(token, link.id);
    responseFuture
      .then(() => {
        deleteSocialMedia();

        closeModal();
      })
      .catch((error) => {
        console.error(error);
        window.alert("Failed to delete custom social media.");
      })
      .finally(() => {
        setDeleteLoading(false);
      });
  }
  return (
    <>
      <OverlayModal onClick={closeModal} />

      <div
        className={`absolute top-12 px-7 pt-4 pb-4 -left-2 -right-2 z-20 border rounded-md border-stone-300 bg-white shadow-lg`}
      >
        <p className="font-medium"> Imagen personalizada </p>
        <div className="mt-4 flex items-center">
          <input
            ref={fileRefIcon}
            onChange={(e) =>
              handleImageUpload(e, setImageSource, setImageFormData)
            }
            className="hidden"
            accept="image/x-png,image/jpeg"
            type="file"
          />
          <input
            ref={fileRefContent}
            onChange={(e) =>
              handleImageUpload(
                e,
                setImageSourceContent,
                setImageFormDataContent,
                "compress-less"
              )
            }
            className="hidden"
            accept="image/x-png,image/jpeg"
            type="file"
          />
        </div>
        <form
          className="mt-4 flex flex-col text-sm"
          action="PUT"
          onSubmit={handleSubmit(handleEditCustomSocialMedia)}
        >
          <label className="mt-0 text-stone-800 font-medium" htmlFor="title">
            Título
          </label>

          <input
            className="mt-1 px-4 py-2 border outline-none placeholder:text-stone-500"
            type="text"
            id="title"
            name="title"
            placeholder="Título"
            {...register("title")}
            required
          />
          <label className="mt-4 text-stone-800 font-medium" htmlFor="title">
            Icono
          </label>
          <div className="mt-1 flex items-center">
            <button
              type="button"
              className="filter hover:contrast-[0.9]"
              onClick={() => handleOpenFilePicker(fileRefIcon)}
            >
              <img
                className="max-w-[4rem] h-16 aspect-square object-cover rounded-md cursor-pointer"
                alt={`Foto de ${link.title}`}
                src={overrideUndefinedImage("image", imageSource)}
                onClick={() => handleOpenFilePicker(fileRefIcon)}
              />
            </button>
            <p
              className="ml-4 text-xs font-medium cursor-pointer text-blue-500 hover:text-blue-600"
              onClick={() => handleOpenFilePicker(fileRefIcon)}
            >
              Seleccionar imagen ( *png, *jpg, *jpeg )
            </p>
          </div>

          <label className="mt-4 text-stone-800 font-medium" htmlFor="title">
            Imagen
          </label>

          <div className="mt-1 flex items-center">
            <button
              type="button"
              className="filter hover:contrast-[0.9]"
              onClick={() => handleOpenFilePicker(fileRefContent)}
            >
              <img
                className="max-w-[4rem] h-16 aspect-square object-cover rounded-md cursor-pointer"
                alt={`Añadir foto`}
                src={imageSourceContent}
              />
            </button>
            <p
              className="ml-4 text-xs font-medium cursor-pointer text-blue-500 hover:text-blue-600"
              onClick={() => handleOpenFilePicker(fileRefContent)}
            >
              Seleccionar imagen ( *png, *jpg, *jpeg )
            </p>
          </div>
          <div className="mt-4 flex items-center justify-between">
            {deleteLoading ? (
              <LoadingSpinnerV2
                size={20}
                className="ml-1"
                fill={"fill-red-600"}
                color="dark:text-red-200"
              />
            ) : (
              <button type="button" onClick={handleDeleteCustomSocialMedia}>
                <HiTrash className="text-red-600 cursor-pointer" size={28} />
              </button>
            )}
            <div className="flex flex-col sm:flex-row gap-2">
              <button
                onClick={closeModal}
                className="px-6 py-1 font-medium w-fit rounded-md border border-stone-300 text-stone-900 bg-stone-100"
              >
                Cancelar
              </button>

              <LoadingButton
                type="submit"
                isLoading={isLoading}
                className="px-6 py-1 font-medium w-fit rounded-md border border-transparent transition-colors duration-200 text-stone-100 bg-stone-950 disabled:bg-stone-300 disabled:text-stone-700"
                disabled={
                  !linkHasBeenModified() &&
                  !imageFormData &&
                  !imageFormDataContent
                }
              >
                Actualizar
              </LoadingButton>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default EditImageTypeModal;
