import { useState } from "react";
import { useForm } from "react-hook-form";
import LoadingButton from "../../components/LoadingButton";
import { createAccountService } from "../admin/Api/UserServices";
import { useNavigate } from "react-router-dom";
import { ROUTER_INITIAL } from "../../config/Constant";
import { RegisterSuccessModal } from "./Components/RegisterSuccessModal";
import { Dialog } from "@radix-ui/react-dialog";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { toast } from 'react-toastify';


export function RegisterFormV3(props) {
  const { register, handleSubmit, watch } = useForm();
  const [errorRegister, setErrorRegister] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { hideRegisterForm,onSuccessfulRegistration,switchToLoginForm   } = props;

  const password = watch("password");
  const confirmPassword = watch("confirmPassword");

  function handleCloseSuccessRegister() {
    setOpenSuccess(false);
    hideRegisterForm();
  }

   async function handleRegister(data) {
    setLoading(true);

    if (password !== confirmPassword) {
      setErrorRegister("Las contraseñas no coinciden");
      setLoading(false);
      return;
    }

    createAccountService(data)
      .then((response) => {
        setErrorRegister("");
        setOpenSuccess(true);

        toast.success("Cuenta Creada!, Verifique su correo para confirmar su registro", {
          position: "top-center",
          bodyClassName: "text-sm",
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          theme: "colored",
        });
        switchToLoginForm();
      })
      .catch((error) => {
        if (error.code) {
          const code = error.code;
          if (code === "auth/email-already-in-use") {
            setErrorRegister(
              "Este correo electrónico ya se encuentra en uso, intenta con otro"
            );
            return;
          }
          if (code === "auth/network-request-failed") {
            setErrorRegister(
              "Error al crear la cuenta, revisa tu conexión a internet"
            );
            return;
          }
          setErrorRegister(code);
          return;
        }
        setErrorRegister("Error al crear la cuenta, intente con otro correo");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      <div className="flex flex-col">
     
        <div className=" mt-6 flex flex-col w-full">
          <form
            onSubmit={handleSubmit(handleRegister)}
          >
            <div className="w-full text-input-group relative">
              <input
                id="email"
                className="login-input mb-4 px-4 py-2 text-sm font-medium transition-font-size w-full border-2 text-input-login"
                type="text"
                {...register("email")}
                required
              />
              <label
                htmlFor="email"
                className="pointer-events-none transition-all"
              >
                Correo electrónico
              </label>
            </div>
             <div className="mt-2 w-full text-input-group relative">
              <input
                id="password"
                className="login-input mb-4 px-4 py-2 text-sm font-medium transition-font-size w-full border-2 text-input-login"
                type={showPassword ? "text" : "password"} // Cambiar el tipo de entrada en función de showPassword
                {...register("password")}
                required
              />
              <label
                htmlFor="password"
                className="pointer-events-none transition-all"
              >
                Contraseña
              </label>
              <button
                type="button"
                className=" text-white p-2 text-lg rounded-md absolute top-1/3 right-2  transform -translate-y-1/2 cursor-pointer bg-neutral-800"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <AiOutlineEyeInvisible />
                ) : (
                  <AiOutlineEye />
                )}
              </button>
            </div>
            <div className="mt-2 w-full text-input-group relative ">
              <input
                id="confirmPassword"
                className="login-input mb-4 px-4 py-2 text-sm font-medium transition-font-size w-full border-2 text-input-login"
                type="password"
                {...register("confirmPassword")}
                required
              />
              <label
                htmlFor="confirmPassword"
                className="pointer-events-none transition-all"
              >
                Confirmar Contraseña
              </label>
            </div>
            <p className="text-black text-sm mt-2">
              Ingrese su <strong className="font-extrabold">correo electrónico</strong> para crear una nueva cuenta
            </p>
            {errorRegister ? (
              <div className="px-2 py-1 w-fit bg-red-200 text-red-800 border-red-300 00 ">
                {errorRegister}
              </div>
            ) : (
              <></>
            )}
            <LoadingButton
              isLoading={loading}
              className="px-4 py-3 mt-2 mb-4 w-full relative flex justify-center transition-font-size rounded-xl font-semibold text-stone-100 bg-stone-950"
            >
              CREAR CUENTA
            </LoadingButton>

            
          </form>
        </div>
        <Dialog open={openSuccess}>
          <RegisterSuccessModal onConfirm={handleCloseSuccessRegister} />
        </Dialog>
      </div>
    </>
  );
}
