
import CardContainer from "../../components/CardContainer";
import React from 'react';
import SalesTable from './Components/SalesTable';
import { useSelector } from "react-redux";


export function SalesManager() {
  const token = useSelector((state) => state.login.token);

  return (
    <div className="pt-8 w-full flex flex-col font-montserrat mb-8">
      <div className="px-8 flex justify-between">
        <h2 className="font-medium text-xl min-w-0 whitespace-nowrap overflow-hidden text-ellipsis">
          Ventas
        </h2>
        
      </div>
      
      <div className="mt-4 px-6 gap-x-8 gap-y-6 grid grid-cols-1">
        <CardContainer className="px-6 py-4 shadow-md">
          {/* <div className="w-full flex items-center justify-between">
            <h2 className="font-medium"> Registro de Ventas </h2>
          </div> */}
          <div className="mt-2 overflow-x-auto relative sm:rounded-lg">
            <SalesTable token={token}/>

          </div>
        </CardContainer>
      </div>
    </div>
  );
}
