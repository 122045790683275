import { createSlice } from "@reduxjs/toolkit";
import { auth } from "../firebase/FirebaseUtils";

const initialData = {
  token: localStorage.getItem("token"),
  userRole: localStorage.getItem("userRole"),
};

export const loginSlice = createSlice({
  name: "login",
  initialState: initialData,
  reducers: {
    userLogin: (state, action) => {
      const token = action.payload.token;
      const userRole = action.payload.userRole;
      state.token = token;

      if (userRole) {
        state.userRole = userRole;
        localStorage.setItem("userRole", userRole);
      }

      localStorage.setItem("token", token);
    },
    userLogout: (state) => {
      state.token = null;
      state.userRole = null;
      localStorage.removeItem("token");
      localStorage.removeItem("userRole");
      auth.signOut();
    },
  },
});
export const { userLogin, userLogout } = loginSlice.actions;
export default loginSlice.reducer;
