import { useState } from "react";

import { BsShieldFillCheck } from "react-icons/bs";
import { BiSupport } from "react-icons/bi";
import { useForm } from "react-hook-form";

import ModalBase from "../../pages/home/EditCardComponents/ModalBase/ModalBase";
import LoadingSpinnerV2 from "../LoadingSpinnerV2";

import { sendSupportEmail } from "../../pages/admin/Api/AdminServices";
import { useSelector } from "react-redux";
import { auth } from "../../firebase/FirebaseUtils";

export const SupportModal = (props) => {
  const { setShowModal, showModal } = props;

  const { register, handleSubmit, reset } = useForm();

  const [formSent, setFormSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const token = useSelector((state) => state.login.token);

  function closeModal() {
    setShowModal(false);
  }

  async function handleSupportSubmit(data) {
    setLoading(true);

    data.userAgent = window.navigator.userAgent;
    data.email = auth.currentUser.email;

    sendSupportEmail(data, token)
      .then((response) => {
        setFormSent(true);
        resetForm();
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  }

  function resetForm() {
    reset();
  }

  return (
    <ModalBase setShowModal={setShowModal} showModal={showModal}>
      <>
        <div className=" text-stone-900 max-w-3xl">
          <div className="flex items-center">
            <BiSupport size={24} />
            <p className="ml-2 font-medium text-base"> Soporte </p>
          </div>

          <p className="mt-4 tracking-[0.02em] text-sm text-stone-500">
            Si experimentas algún problema con tu cuenta o necesitas información
            adicional no dudes en contactárnos por los siguientes medios.
          </p>
          <div className="mt-4 text-sm">
            <form
              onSubmit={handleSubmit(handleSupportSubmit)}
              className="flex flex-col"
            >
              <label
                className="text-stone-800 font-medium"
                htmlFor="description"
              >
                Mensaje
              </label>
              <textarea
                className="mt-1 px-4 py-2 border outline-none w-full resize-none"
                {...register("content")}
                name="content"
                id="content"
                rows={3}
                maxLength={999}
                placeholder="Describe el problema"
                required
              ></textarea>

              <p className="tracking-[0.02em] mt-2 text-sm">
                O contáctanos por: <strong> soporte@soyyo.digital </strong>
              </p>

              {formSent ? (
                <div className="mt-2 flex items-center mx-auto text-emerald-600">
                  <BsShieldFillCheck />
                  <p className="tracking-[0.02em] ml-1">
                    Envíado exitosamente, nos pondrémos en contacto pronto
                  </p>
                </div>
              ) : (
                <></>
              )}

              <div className="mt-2 flex items-center justify-end">
                <div className="flex sm:flex-row gap-2">
                  <button
                    type="button"
                    onClick={closeModal}
                    className="px-6 py-1 font-medium w-fit rounded-md border border-stone-300 text-stone-900 bg-stone-100"
                  >
                    Cancelar
                  </button>
                  <button
                    type="submit"
                    className="px-6 py-1 relative font-medium w-fit rounded-md border border-transparent transition-colors duration-200 text-stone-100 bg-stone-950 disabled:bg-stone-300 disabled:text-stone-700"
                  >
                    <p className={`${loading ? "invisible" : ""}`}> Envíar </p>
                    <LoadingSpinnerV2
                      className={`${
                        !loading ? "invisible" : ""
                      } absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2`}
                      color="dark:text-stone-600"
                      fill="fill-stone-200"
                      size={16}
                    />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    </ModalBase>
  );
};
