/* eslint-disable jsx-a11y/alt-text */
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../firebase/FirebaseUtils";
import { ROUTER_INITIAL } from "../../config/Constant";

import LoadingSpinnerV2 from "../../components/LoadingSpinnerV2";
import { BsShieldFillCheck } from "react-icons/bs";
import { MdWarning } from "react-icons/md";

import { useForm } from "react-hook-form";

import imageReset from "../../../src/assets/images/background/image-reset.png";
import soyYoLogoNegro from "../../../src/assets/images/soy-yo-logo-negro.png";
import soyYoNameNegro from "../../../src/assets/images/soy-yo-nombre-negro.png";
import spark from "../../../src/assets/images/background/spark.png";

import cardSVG from "../../../src/assets/svg/card.svg";
import commentSVG from "../../../src/assets/svg/comment.svg";
import signalSVG from "../../../src/assets/svg/signal.svg";

import "../../styles/loginForm.css"
import "../../styles/verifyEmail.css";
import "../landing_page/Components/LandingPageStyles.css"
import { AiOutlineArrowLeft } from "react-icons/ai";


const ResetPasswordForm = () => {
  const [loading, setLoading] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [errorFound, setErrorFound] = useState(false);

  const { register, handleSubmit } = useForm();
  const history = useNavigate();


  function handleSendResetCode(data) {
    const email = data.email;
    const responseFuture = sendPasswordResetEmail(auth, email);

    setLoading(true);
    responseFuture
      .then((response) => {
        setMessageSent(true);

        setTimeout(() => {
          setMessageSent(false);
        }, 5000);
      })
      .catch((error) => {
        console.error(error);
        setErrorFound(true);
        setTimeout(() => {
          setErrorFound(false);
        }, 5000);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleGoBack() {
    return history(ROUTER_INITIAL);
  }


  return (
    <div className="font-montserrat flex w-full h-full bg-stone-950 radial-background">
      <div className="md:w-[45%] min-w-[22rem] md:max-w-[30rem] rounded-none md:rounded-r-[3rem] w-full h-full flex flex-col items-center bg-stone-50">
      <button onClick={handleGoBack}>
          <AiOutlineArrowLeft className="p-2 left-2 top-2 text-xl absolute cursor-pointer box-content rounded-full hover:bg-stone-800/10" />
        </button>

        <div className="p-4 mt-8 opacity-80 rounded-3xl flex flex-row">
          <img className="h-9" src={soyYoLogoNegro} alt="SoyYo logo"></img>
          <img className="h-9" src={soyYoNameNegro} alt="SoyYo marca"></img>
        </div>
        <h1 className="mt-[8vh] mb-14 font-manrope text-2xl transition-font-size font-medium text-stone-700">
          ¿Olvidaste tu contraseña?
        </h1>
        <div className="max-w-[26rem] flex flex-col w-full">
          <form
            onSubmit={handleSubmit(handleSendResetCode)}
            className="px-6 flex flex-col"
          >
            <div className="mb-4 w-full text-input-group relative">
              <input
                className="login-input px-4 py-3 text-sm font-medium transition-font-size w-full rounded-lg text-input-login"
                type="text"
                {...register("email")}
                required
              />
              <label
                htmlFor="email"
                className="pointer-events-none transition-all"
              >
                Correo electrónico
              </label>
            </div>

            {messageSent ? (
              <div className="flex items-center mx-auto text-emerald-600">
                <BsShieldFillCheck />
                <p className="ml-2">Código de recuperación envíado</p>
              </div>
            ) : (
              <></>
            )}

            {errorFound ? (
              <div className="flex items-center mx-auto text-red-600">
                <MdWarning />
                <p className="ml-2">Ocurrió un error al envíar el correo</p>
              </div>
            ) : (
              <></>
            )}

            <button
              className="px-4 py-3 relative w-full mt-8 flex transition-font-size rounded-xl font-semibold text-stone-100 bg-stone-950"
              type="submit"
            >
              <div className="mx-auto">
                <LoadingSpinnerV2
                  className={`${
                    !loading ? "invisible" : ""
                  } absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2`}
                  color="dark:text-stone-600"
                  fill="fill-stone-200"
                  size={24}
                />
                <div className={loading ? "invisible" : ""}>Envíar código</div>
              </div>
            </button>
          </form>

          <Link
            className="mt-4 mx-auto text-sm text-stone-600 hover:text-stone-800"
            to={ROUTER_INITIAL}
          >
            <div className="flex items-center">
              <div className="ml-2">Volver al inicio de sesión</div>
            </div>
          </Link>
        </div>
      </div>

      <div className="h-full hidden relative md:flex w-full px-2 justify-center items-center">
        {/* TODO: Refactorizar estas imagenes en sus propios componentes */}
        <img
          className="absolute wiggle-class-animation neon-light-animation left-0 lg:left-[5%] transition-[left] duration-500 top-24 w-64"
          src={commentSVG}
          style={{animationDelay: "200ms"}}
        />
        <img
          className="absolute wiggle-class-animation neon-light-animation right-0 lg:right-[15%] transition-[right] duration-500 top-16 w-64"
          src={signalSVG}
          style={{animationDelay: "500ms"}}
        />
        <img
          className="absolute wiggle-class-animation neon-light-animation right-4 lg:right-[20%] transition-[right] duration-500 bottom-24 w-80"
          src={cardSVG}
          style={{animationDelay: "0ms"}}
        />
        <img className="pulse-star absolute left-3/4 mb-8 w-8" src={spark} />
        <img
          className="pulse-star absolute left-1/4 top-3/4 mb-8 w-8"
          src={spark}
        />
        <img
          className="pulse-star absolute left-1/2 top-1/4 mb-8 w-8"
          src={spark}
        />
        <div className="skew-24 absolute border-2 border-white max-h-[36rem] h-full aspect-[3/8] rounded-xl"></div>

        <img
          className="absolute mb-8 max-w-[25vw] max-h-[32rem]"
          src={imageReset}
        />
      </div>
    </div>
  );
};

export default ResetPasswordForm;
