import ModalBase from "../../pages/home/EditCardComponents/ModalBase/ModalBase";

import { useForm } from "react-hook-form";
import { useState } from "react";
import LoadingSpinnerV2 from "../LoadingSpinnerV2";

import { IoIosLock } from "react-icons/io";

import { EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth";
import { auth } from "../../firebase/FirebaseUtils";
import { useDispatch } from "react-redux";
import { userLogin } from "../../redux/loginSlice";

export const RequestCredentialModal = (props) => {
  const { setShowModal, showModal, onAuthenticated } = props;
  const dispatch = useDispatch();

  const { register, handleSubmit } = useForm({});

  const [loading, setLoading] = useState(false);

  function closeModal() {
    setShowModal(false);
  }

  async function handleRequestCredential(data) {
    const credential = EmailAuthProvider.credential(
      auth.currentUser.email,
      data.password
    );
    
    setLoading(true);

    const response = reauthenticateWithCredential(auth.currentUser, credential);
    response
      .then((response) => {
        setLoading(false);
        const token =  response.user.accessToken;
        dispatch(userLogin({token}));
        closeModal();

        if(onAuthenticated) {
            onAuthenticated();
        }
      })
      .catch((error) => {
        setLoading(false);
        window.alert("Error autenticando el usuario");
      });
  }

  return (
    <ModalBase setShowModal={setShowModal} showModal={showModal}>
      <>
        <div className="pt-2 text-stone-900 max-w-3xl">
          <div className="flex items-center">
            <IoIosLock size={24} />
            <p className="ml-2 font-medium text-base">Verificación de cuenta</p>
          </div>

          <p className="mt-3 text-sm text-stone-500">
            Necesitamos que verifiques tus credenciales para poder continuar.
          </p>
          <div className="mt-4 text-sm">
            <form
              onSubmit={handleSubmit(handleRequestCredential)}
              className="flex flex-col"
            >
              <label className="text-stone-800 font-medium" htmlFor="email">
                Correo electrónico
              </label>
              <input
                className="px-4 py-2 border outline-none w-full text-stone-500"
                type="email"
                {...register("email", { value: auth.currentUser.email })}
                id="email"
                name="email"
                placeholder="Correo electrónico"
                disabled={true}
                required
              />
              <label
                className=" mt-2 text-stone-800 font-medium"
                htmlFor="password"
              >
                Contraseña
              </label>
              <input
                className="px-4 py-2 border outline-none w-full"
                type="password"
                {...register("password")}
                id="password"
                name="password"
                placeholder="Contraseña"
                required
              />

              <div className="mt-6 flex items-center justify-end">
                <div className="flex sm:flex-row gap-2">
                  <button
                    type="button"
                    onClick={closeModal}
                    className="px-6 py-1 font-medium w-fit rounded-md border border-stone-300 text-stone-900 bg-stone-100"
                  >
                    Cancelar
                  </button>
                  <button
                    type="submit"
                    className="px-6 py-1 relative font-medium w-fit rounded-md border border-transparent transition-colors duration-200 text-stone-100 bg-stone-950 disabled:bg-stone-300 disabled:text-stone-700"
                  >
                    <p className={`${loading ? "invisible" : ""}`}> Envíar </p>
                    <LoadingSpinnerV2
                      className={`${
                        !loading ? "invisible" : ""
                      } absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2`}
                      color="dark:text-stone-600"
                      fill="fill-stone-200"
                      size={16}
                    />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    </ModalBase>
  );
};
