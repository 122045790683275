export function LoadingUserTableData() {
  return (
    <div className="animate-pulse">
      <div className="bg-stone-300 h-5 rounded-md"></div>
    </div>
  );
}

export function UserLoadingTableRow() {
  return (
    <>
      <tr className="">
        <td className="px-2 py-4">
          <LoadingUserTableData />
        </td>
        <td className="px-2 py-4">
          <LoadingUserTableData />
        </td>
        <td className="px-2 py-4">
          <LoadingUserTableData />
        </td>
        <td className="px-2 py-4">
          <LoadingUserTableData />
        </td>
        <td className="px-2 py-4">
          <LoadingUserTableData />
        </td>
        <td className="px-2 py-4">
          <LoadingUserTableData />
        </td>
      </tr>
      <tr className="">
        <td className="px-2 py-4">
          <LoadingUserTableData />
        </td>
        <td className="px-2 py-4">
          <LoadingUserTableData />
        </td>
        <td className="px-2 py-4">
          <LoadingUserTableData />
        </td>
        <td className="px-2 py-4">
          <LoadingUserTableData />
        </td>
        <td className="px-2 py-4">
          <LoadingUserTableData />
        </td>
        <td className="px-2 py-4">
          <LoadingUserTableData />
        </td>
      </tr>
    </>
  );
}
