
import React, { useState } from 'react';
import Modal from 'react-modal';

const ConfirmationModal = ({ isOpen, message, onConfirm, onClose }) => {
  const confirmAction = () => {
    if (onConfirm) {
      onConfirm();
    }
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="modal-container"
      overlayClassName="modal-overlay bg-black bg-opacity-50"
      contentLabel="Confirmation Modal"
    >
      <div className="modal-content bg-white shadow-md p-6 rounded-lg border-2 border-neutral-400 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <p className="text-base mb-4">{message}</p>
        <div className="flex space-x-4 justify-center text-center text-sm">
          <button
            className="bg-green-500 text-white px-3 py-1 rounded-lg hover:bg-green-600"
            onClick={confirmAction}
          >
            Aceptar
          </button>
          <button
            className="bg-red-500 text-white px-3 py-1 rounded-lg hover:bg-red-600"
            onClick={onClose}
          >
            Cancelar
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
