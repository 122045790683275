import React, { useState } from 'react';

const CustomComboBox = ({ options, onChange }) => {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <select className="border border-gray-300 rounded px-3 py-1 mr-2 text-sm" onChange={handleChange}>
      {options.map((option, index) => (
        <option key={index} value={option.value}>{option.label}</option>
      ))}
    </select>
  );
};

const Filters = ({ onFilterChange }) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('2');
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState('select_month');

  const handleChangeStartDate = (event) => {
    setStartDate(event.target.value);
  };

  const handleChangeEndDate = (event) => {
    setEndDate(event.target.value);
  };

  const handleStatusChange = (value) => {
    setSelectedStatus(value === '0' ? null : value);
    onFilterChange(value === '0' ? null : value, selectedYear); // Pasar el año seleccionado
  };

  const handleYearChange = (value) => {
    setSelectedYear(value === 'select_year' ? null : value);
    onFilterChange(selectedStatus, value === 'select_year' ? null : value); // Pasar el estado seleccionado
  };

  const handleMonthChange = (value) => {
    setSelectedMonth(value);
    onFilterChange(selectedStatus, selectedYear, value === 'select_month' ? null : value);
  };
  

  const generateStatusOptions = () => {
    return [
      { value: '2', label: 'Pagados' },
      { value: '1', label: 'Pendientes' },
      { value: '0', label: 'Todos' },
    ];
  };

  const getCurrentYear = () => {
    const today = new Date();
    return today.getFullYear();
  };

  const generateMonthsOptions = () => {
  const months = [
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
  ];
  const options = months.map((month, index) => ({
    value: index + 1,
    label: month
  }));
  options.unshift({ value: 'select_month', label: 'Seleccionar Mes' });
  return options;
};

  const generateYearsOptions = () => {
    const currentYear = getCurrentYear();
    const startYear = 2022;
    const years = [];
    for (let year = startYear; year <= currentYear; year++) {
      years.push({ value: year, label: year });
    }
    const options = years.map((year) => year);
    options.unshift({ value: 'select_year', label: 'Seleccionar Año' });
    return options;
  };

  const comboBoxOptions = [
    generateStatusOptions(),
    generateYearsOptions(),
    generateMonthsOptions(),
  ];

  return (
    <div className="flex items-center flex-wrap">
      <div className="flex flex-col mb-2">
        <label htmlFor="status" className="text-sm font-medium">Filtrar por:</label>
        <div className="flex items-center">
          <CustomComboBox id="status" options={comboBoxOptions[0]} onChange={handleStatusChange} />
          <CustomComboBox id="year" options={comboBoxOptions[1]} onChange={handleYearChange} />
          <CustomComboBox id="month" options={comboBoxOptions[2]} onChange={handleMonthChange} />
        </div>
      </div>
      <div className="flex flex-col mb-2">
        <label htmlFor="dates" className="text-sm font-medium">Personalizar Fecha</label>
        <div className="flex items-center mr-2 text-sm">
          <input 
            id="startDate"
            type="date" 
            className="border border-gray-300 rounded px-3 py-1 mr-2 text-"
            value={startDate}
            onChange={handleChangeStartDate}
          />
          <input 
            id="endDate"
            type="date" 
            className="border border-gray-300 rounded px-3 py-1 mr-2 text-"
            value={endDate}
            onChange={handleChangeEndDate}
          />
        </div>
      </div>
    </div>
  );
};

export default Filters;