import { off } from "process";



export function scrollToById(id) {
    return () => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    };
  }
