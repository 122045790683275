import { useEffect, useState } from "react";

function secondsToTime(seconds) {
  if (seconds === undefined) {
    return "";
  }
  let minutes = Math.floor(seconds / 60);
  seconds = seconds % 60;

  minutes = minutes.toString().padStart(2, "0");
  seconds = seconds.toString().padStart(2, "0");

  return `- ${minutes}:${seconds}`;
}

export function SendEmailTimer(props) {
  const { className, text, delay, onClick } = props;

  const [seconds, setSeconds] = useState(undefined);

  function handleClick() {
    if(seconds === 0 || seconds === undefined) {
      onClick();
      setSeconds(delay);
    }
  }

  useEffect(() => {
    let interval;

    interval = setInterval(() => {
      if (seconds === 0 || seconds === undefined) {
        setSeconds(undefined);
        clearInterval(interval);
        return;
      }
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [seconds]);
  return (
    <button className={className} onClick={handleClick}>
      {text} {secondsToTime(seconds)}
    </button>
  );
}
