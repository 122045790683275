export default function OverlayModal(props) {
  const { onClick } = props;

  return (
    <div
      className="fixed z-10 inset-0 flex justify-center items-center bg-stone-900 opacity-20"
      onClick={onClick}
    ></div>
  );
}
