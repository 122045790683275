import { polyfillCountryFlagEmojis } from "country-flag-emoji-polyfill";


export function CountryCodeOptions() {
    // TODO: Debería estar en un array de objects ? [{ codigo: emoji }]
    // Y no ser un componente ?
    // No se cuál es mejor para el rendimiento...

    // TODO: Verificar que los códigos son correctos ??
    // Esto es necesario porque el teléfono no se separa a nivel de base de datos

    polyfillCountryFlagEmojis();
  return (
    <>
      <option value="1"> 🇨🇦 +1 </option>
      <option value="20"> 🇪🇬 +20 </option>
      <option value="30"> 🇬🇷 +30 </option>
      <option value="32"> 🇧🇪 +32 </option>
      <option value="33"> 🇫🇷 +33 </option>
      <option value="36"> 🇭🇺 +36 </option>
      <option value="39"> 🇮🇹 +39 </option>
      <option value="43"> 🇦🇹 +43 </option>
      <option value="45"> 🇩🇰 +45 </option>
      <option value="49"> 🇩🇪 +49 </option>
      <option value="51"> 🇵🇪 +51 </option>
      <option value="52"> 🇲🇽 +52 </option>
      <option value="54"> 🇦🇷 +54 </option>
      <option value="55"> 🇧🇷 +55 </option>
      <option value="56"> 🇨🇱 +56 </option>
      <option value="57"> 🇨🇴 +57 </option>
      <option value="58"> 🇻🇪 +58 </option>
      <option value="60"> 🇲🇾 +60 </option>
      <option value="61"> 🇦🇺 +61 </option>
      <option value="62"> 🇮🇩 +62 </option>
      <option value="81"> 🇯🇵 +81 </option>
      <option value="86"> 🇨🇳 +86 </option>
      <option value="91"> 🇮🇳 +91 </option>
      <option value="93"> 🇦🇫 +93 </option>
      <option value="98"> 🇮🇷 +98 </option>
      <option value="212"> 🇲🇦 +212 </option>
      <option value="213"> 🇩🇿 +213 </option>
      <option value="244"> 🇦🇴 +244 </option>
      <option value="251"> 🇪🇹 +251 </option>
      <option value="254"> 🇰🇪 +254 </option>
      <option value="264"> 🇳🇦 +264 </option>
      <option value="352"> 🇱🇺 +352 </option>
      <option value="353"> 🇮🇪 +353 </option>
      <option value="355"> 🇦🇱 +355 </option>
      <option value="357"> 🇨🇾 +357 </option>
      <option value="358"> 🇫🇮 +358 </option>
      <option value="359"> 🇧🇬 +359 </option>
      <option value="372"> 🇪🇪 +372 </option>
      <option value="375"> 🇧🇾 +375 </option>
      <option value="376"> 🇦🇩 +376 </option>
      <option value="377"> 🇲🇨 +377 </option>
      <option value="385"> 🇭🇷 +385 </option>
      <option value="420"> 🇨🇿 +420 </option>
      <option value="502"> 🇬🇹 +502 </option>
      <option value="504"> 🇭🇳 +504 </option>
      <option value="505"> 🇳🇮 +505 </option>
      <option value="506"> 🇨🇷 +506 </option>
      <option value="591"> 🇧🇴 +591 </option>
      <option value="593"> 🇪🇨 +593 </option>
      <option value="598"> 🇺🇾 +598 </option>
      <option value="595"> 🇵🇾 +595 </option>
      <option value="855"> 🇰🇭 +855 </option>
      <option value="880"> 🇧🇩 +880 </option>
      <option value="961"> 🇱🇧 +961 </option>
      <option value="965"> 🇰🇼 +965 </option>
      <option value="973"> 🇧🇭 +973 </option>
    </>
  );
}

export default CountryCodeOptions