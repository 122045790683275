import React, { useEffect, useState } from 'react';
import CardContainer from '../../components/CardContainer';
import { getAllModules } from './Api/Modules';
import { useSelector } from "react-redux";
import {FaMoneyBill, FaCartPlus} from 'react-icons/fa';


function ModulesClientPage() {
  const token = useSelector((state) => state.login.token);
  const [modules, setModules] = useState([]);
  const [selectedModule, setSelectedModule] = useState(null);

  useEffect(() => {
    // Llamada a getAllModules para obtener los módulos
    getAllModules(token)
      .then(response => {
        console.log('Contenido de los módulos:', response.data);
        setModules(response.data);
      })
      .catch(error => {
        console.error('Error al obtener los módulos:', error);
      });
  }, []); // El array vacío como segundo argumento asegura que useEffect solo se ejecute una vez al montar el componente

  const handleModuleClick = (module) => {
    console.log('Datos del módulo seleccionado:', module);
    setSelectedModule(module);
  };

  return (
    <div className="pt-8 px-8 w-full flex flex-col font-montserrat">
      <h2 className="font-medium text-xl mb-4">Tienda de Módulos</h2>
      <div className="flex gap-6">
        <CardContainer className="flex-1 p-4 shadow-md">
          <h2 className="font-medium text-lg mb-4">Disfuta nuestros Módulos Disponibles</h2>
          <div className=" rounded-xl p-4 shadow-inner">
            {modules.map(module => (
              <div key={module.id} className="cursor-pointer bg-neutral-800 text-white hover:bg-neutral-700 p-3 rounded-lg mb-2" onClick={() => handleModuleClick(module)}>
                {module.name}
              </div>
            ))}
          </div>
        </CardContainer>

        <CardContainer className="flex-1 p-4 shadow-md">
          <h2 className="font-medium text-lg mb-4">{selectedModule ? selectedModule.name : 'Selecciona un módulo'}</h2>
          <div className="bg-teal-500 text-white rounded-xl p-4 shadow-inner">
            {selectedModule && (
              <>
                <div className="flex items-center mb-4">
                  <strong className="text-4xl font-bold">{selectedModule.price}</strong>
                  <p className="text-xl ml-2"> Bs.</p>
                </div>
                <p className="text-xl">Tiempo de Subscripción: {selectedModule.duration} días</p>
              </>
            )}
          </div>
        
            <div className="flex justify-center mt-6 space-x-2">
            <button className="flex items-center justify-center w-full py-4 bg-neutral-800 hover:bg-neutral-700 text-white rounded-lg focus:outline-none">
                <FaCartPlus className="mr-2" /> Agregar al Carrito
            </button>
            <button className="flex items-center justify-center w-full py-4 bg-neutral-800 hover:bg-neutral-700 text-white rounded-lg focus:outline-none">
                <FaMoneyBill className="mr-2" /> Comprar
            </button>
            </div>
        </CardContainer>
      </div>

      <div className="mt-6">
        <CardContainer className="p-4 shadow-md">
          <h2 className="font-medium text-lg mb-4">Descripción</h2>
          <div className=" rounded-xl p-4 shadow-inner">
            {selectedModule && <p>{selectedModule.description}</p>}
          </div>
        </CardContainer>
      </div>
    </div>
  );
}

export { ModulesClientPage };
