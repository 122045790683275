import { useState } from "react";

import { MdExpandMore } from "react-icons/md";

export function OrderSummaryCollapsible(props) {
  const { children } = props;
  const [expanded, setExpanded] = useState(false);

  function toggleCollapsible() {
    setExpanded(!expanded);
  }
  return (
    <div>
      <button
        onClick={toggleCollapsible}
        aria-expanded={expanded}
        aria-label="Expandir orden"
        className="w-full h-0.5 bg-stone-200 flex justify-center items-center"
      >
        <div className="p-0.5 bg-stone-300 rounded-full">
          <MdExpandMore
            className={`${expanded ? "rotate-180" : ""} transition-all`}
          />
        </div>
      </button>
      <div
        data-testid="collapsible-content"
        aria-hidden={!expanded}
        className={`grid ${
          expanded ? "grid-rows-[1fr]" : "grid-rows-[0fr]"
        } grid-cols-1 transition-all`}
      >
        <div className="overflow-hidden transition-all h-full">{children}</div>
      </div>
    </div>
  );
}
