import LoadingSpinnerV2 from "./LoadingSpinnerV2";

export default function LoadingButton(props) {
  const { className, isLoading, disabled, onClick, children, type, color, fill } = props;
  
  return (
    <button
      type={type}
      className={`${className} relative`}
      disabled={disabled}
      onClick={(event) => {
        if (isLoading || disabled) {
          event.preventDefault();
          return;
        }
        onClick && onClick()
      }}
    >
      <LoadingSpinnerV2
        color={color ?? "text-stone-500"}
        fill={fill ?? "fill-stone-100"}
        className={`${
          isLoading ? "" : "invisible"
        } absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2`}
        size={20}
      />
      <p className={`${isLoading ? "invisible w-fit" : ""}`}> {children} </p>
    </button>
  );
}
