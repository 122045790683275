import {
  DialogContent,
  DialogOverlay,
  DialogPortal,
} from "@radix-ui/react-dialog";

import { MdMarkEmailRead } from "react-icons/md";
import { SendEmailTimer } from "./SendEmailTimer";
import { sendVerificationEmail } from "../../admin/Api/UserServices";
import { auth } from "../../../firebase/FirebaseUtils";

export function RegisterSuccessModal(props) {
  const { onConfirm } = props;
  return (
    <DialogPortal>
      <DialogOverlay className="fixed inset-0 bg-stone-950/25" />

      <DialogContent className="modal-content-show text-center w-[80vw] max-w-md pb-4 z-50 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border border-stone-300 shadow-lg fixed rounded-xl bg-white">
        <div className="pt-6 pb-1 px-7">
          <h2 className="text-base md:text-lg font-medium">
            ¡Se te ha envíado un correo de verificación!
          </h2>
          <div className="mt-6 p-2 left-0 top-0 -translate-y-full -translate-x-1/2 absolute mx-auto w-fit rounded-full bg-emerald-300">
            <MdMarkEmailRead className="text-2xl text-emerald-600" />
          </div>

          <p className="text-sm mt-2 text-stone-700">
            Revisa tu correo electrónico y entra al enlace para verificar tu
            cuenta.
          </p>

          <div className="text-sm md:text-base mt-7 w-fit mx-auto font-medium flex flex-col">
            <button
              onClick={onConfirm}
              className="px-4 py-1 bg-emerald-700 text-stone-50"
            >
              ¡Entendido!
            </button>
            <SendEmailTimer
              className="mt-3 text-sm hover:underline"
              text="Reenvíar enlace"
              delay={60}
              onClick={() => {
                sendVerificationEmail(auth.currentUser);
              }}
            />
          </div>
        </div>
      </DialogContent>
    </DialogPortal>
  );
}
