export function PaymentFormInput(props) {
  const { label, type, defaultValue, value, required, disabled, placeholder, pattern } = props;
  let { register } = props;
  if (value) {
    register = {};
  }
  const labelId = `ìnput_${label}`;

  return (
    <div className="flex flex-col">
      {label ? (
        <label htmlFor={labelId} className="font-medium">
          {label}
        </label>
      ) : null}
      <input
        id={labelId}
        className={` ${label?"mt-1":""} px-4 py-2 uppercase text-sm rounded-md border outline outline-transparent transition-all duration-300 focus:border-transparent focus:outline-2 border-stone-300 disabled:text-stone-600 invalid:border-red-500 focus:outline-sky-500 placeholder:text-stone-400`}
        defaultValue={defaultValue}
        value={value}
        type={type}
        {...register}
        required={required}
        disabled={disabled}
        placeholder={placeholder}
        pattern={pattern}
      />
    </div>
  );
}
