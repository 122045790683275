import { useEffect, useState } from "react";
import { validateTokenServer } from "../../admin/Api/UserServices";
import { useDispatch, useSelector } from "react-redux";
import { userLogout } from "../../../redux/loginSlice";

export const TOKEN_RESULT = {
  VALID: 0,
  ERROR: 1,
  NEED_VERIFICATION: 2,
  INVALID: 3,
};

export function useVerifyToken(token) {
  const [isVerified, setIsVerified] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    function verify() {
      if(!token) {
        setIsVerified(TOKEN_RESULT.INVALID);
        setUser({})
        setLoading(false);
        return;
      }
      validateTokenServer(token)
        .then((response) => {
          setIsVerified(TOKEN_RESULT.VALID);
          setUser(response.user);
        })
        .catch((error) => {
          setIsVerified(TOKEN_RESULT.INVALID);
          setUser({})

          dispatch( userLogout())
        })
        .finally(() => {
          setLoading(false);
        });
    }
    verify();
  }, [token]);
  return { isVerified, loading, user };
}
