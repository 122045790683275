import { cloneElement } from "react";

export function BenefitsCard(props) {
  const { title, description, icon } = props;

  function renderIcon() {
    return cloneElement(icon, {
      className: "mx-auto text-6xl",
    });
  }

  function renderShadow() {
    return cloneElement(icon, {
      className:
        "group-hover:rotate-6 group-hover:scale-110 text-8xl -translate-y-4 opacity-10 transition-transform absolute pointer-events-none",
    });
  }
  return (
    <div className="group relative flex text-center flex-col ">
      <div className="inset-0 absolute flex items-center justify-center">{renderShadow()}</div>
      <div>{renderIcon()}</div>
      <h3 className="mt-8 mb-1 text-lg font-semibold"> {title} </h3>
      <p className="max-w-sm"> {description} </p>
    </div>
  );
}
