import React, { useEffect, useState } from 'react';
import { FaUser, FaUserFriends, FaBaby, FaChild, FaSun, FaMoon, FaEye, FaEyeSlash } from 'react-icons/fa';
import { ConfBookingListView, getConfBookingDetails } from '../../Api/Booking';

const iconClasses = "cursor-pointer text-white-500 text-xs mr-2";
const buttonClasses = "text-white py-3 px-2 rounded-lg flex flex-col items-center overflow-hidden";


const BookingConfList = ({ id, handleConfigSelect }) => {
  const [configData, setConfigData] = useState([]);

  useEffect(() => {
    // Verificar si id es válido antes de hacer la llamada
    if (id) {
      const fetchData = async () => {
        try {
          const result = await ConfBookingListView(id);
          setConfigData(result);
        } catch (error) {
          console.error('Error al llamar a ConfBookingListView:', error);
        }
      };
      fetchData();
    }
  }, [id]);

  return (
    <>
      <div className="flex flex-wrap gap-2 bg-white items-center w-full mb-2 mx-auto">
        {configData.map((booking, index) => (
          <div
            key={index}
            className={`
              ${booking.status_conf === 0 ? 'bg-red-500' : ''}
              ${booking.hora_inicio !== booking.hora_fin && booking.status_conf >= 1 ? 'bg-blue-400' : ''}
              ${booking.hora_inicio == booking.hora_fin && booking.hora_inicio_tarde !== booking.hora_fin_tarde && booking.status_conf >= 1 ? 'bg-orange-400' : ''}
              ${booking.hora_inicio == booking.hora_fin && booking.hora_inicio_tarde == booking.hora_fin_tarde && booking.hora_inicio_noche !== booking.hora_fin_noche && booking.status_conf >= 1 ? 'bg-purple-500' : ''}
              ${booking.hora_inicio == booking.hora_fin && booking.hora_inicio_tarde == booking.hora_fin_tarde && booking.hora_inicio_noche == booking.hora_fin_noche && booking.status_conf >= 1 ? 'bg-emerald-500' : ''}
              ${buttonClasses}
            `}
            onClick={() => handleConfigSelect(booking)}>
            <div className="flex mb-3">
              {booking.holiday.replace(/\[|\]/g, '').split(',').map((day, dayIndex) => (
                <p
                  key={dayIndex}
                  className={`mx-1 text-xs font-semibold ${
                    day === 'false' ? 'text-neutral-100' : 'text-neutral-800 opacity-50'
                  }`}>
                  {['D', 'L', 'M', 'M', 'J', 'V', 'S'][dayIndex]} 
                </p>
              ))}
            </div>
            <p className="text-xs font-semibold overflow-hidden overflow-ellipsis max-h-20">
              {booking.btn}
            </p>
            <div className="flex mt-3">
              {booking.status_conf === 1 ? (
                <FaUser className={` ${iconClasses}`} />
              ) : (
                <FaUserFriends className={` ${iconClasses}`} />
              )}
              {booking.kids && <FaChild className={`${iconClasses}`} />}
              {booking.teen && <FaBaby className={`${iconClasses}`} />}
              {(booking.hora_inicio !== booking.hora_fin || booking.hora_inicio_tarde !== booking.hora_fin_tarde) && (
                <FaSun className={` ${iconClasses}`} />
              )}
              {booking.hora_inicio_noche !== booking.hora_fin_noche && (
                <FaMoon className={` ${iconClasses}`} />
              )}
              {booking.status_conf > 0 ? (
                <FaEye className={` ${iconClasses}`} />
              ) : (
                <FaEyeSlash className={` ${iconClasses}`} />
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
export { BookingConfList };
