import axios from 'axios';
import { DOMAIN, LOCALHOST_DOMAIN } from "../../../config/Constant";
import { useState } from 'react';


export const getClientContactList = async (customerUserId, token) => {
    try {
        const response = await axios.get(`${DOMAIN}client_contact/get/${customerUserId}/`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};


// Función para crear una nueva configuración de contacto de cliente
export const createClientContactConfig = async (data, token) => {
    try {
        const response = await axios.post(`${DOMAIN}client_contact/config/`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${token}`,
            },
        });

        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getClientContactConfigDetails = async (customerUser, token) => {
    try {
        const response = await axios.get(`${DOMAIN}client_contact/detail/${customerUser}/`, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        return response.data;
    } catch (error) {
        throw error;
    }
};

// Función para actualizar la configuración de contacto de cliente
export const updateClientContactConfig = async (configId, updatedData, token) => {
    try {
        const response = await axios.put(
            `${DOMAIN}client_contact/config/${configId}/`,
            updatedData,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `JWT ${token}`,
                },
            }
        );

        return response.data;
    } catch (error) {
        throw error;
    }
};

// Función para eliminar la configuración de contacto de cliente
export const deleteClientContactConfig = async (configId, token) => {
    try {
        const response = await axios.delete(`${DOMAIN}client_contact/config/${configId}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${token}`,
            },
        });

        return response.data;
    } catch (error) {
        throw error;
    }
};





export const getRegisterById = async (registerId, token) => {
    try {
        const response = await axios.get(`${DOMAIN}register/${registerId}/`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${token}`,
            },
        });

        return response.data;
    } catch (error) {
        throw error;
    }
};

// Función para actualizar un registro por su ID
export const updateRegister = async (registerId, updatedData, token) => {
    try {
        const response = await axios.put(
            `${DOMAIN}client_contact/register/${registerId}/`,
            updatedData,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `JWT ${token}`,
                },
            }
        );

        return response.data;
    } catch (error) {
        throw error;
    }
};

// Función para eliminar un registro por su ID
export const deleteRegister = async (registerId, token) => {
    try {
        const response = await axios.delete(`${DOMAIN}register/${registerId}/`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${token}`,
            },
        });

        return response.data;
    } catch (error) {
        throw error;
    }
};

// Función para obtener la lista de configuraciones para todos los usuarios
export const getAllUserConfigurations = async (token) => {
    try {
        const response = await axios.get(`${DOMAIN}client_contact/config/all_users/`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${token}`,
            },
        });

        return response.data;
    } catch (error) {
        throw error;
    }
};

// Función para crear o actualizar configuraciones para varios usuarios
export const createOrUpdateUserConfigurations = async (data, token) => {
    try {
        const response = await axios.post(`${DOMAIN}client_contact/config/all_users/`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${token}`,
            },
        });

        return response.data;
    } catch (error) {
        throw error;
    }
};