import React, { useEffect, useState } from 'react';
import { getAllModules, deleteModuleById } from '../Api/Modules';
import { FaEye, FaPen, FaTrash } from 'react-icons/fa';
import ModulesForm from './ServicesForm';
import CardContainer from "../../../components/CardContainer";
import ConfirmationModal from './ConfirmationModal';

function ModulesTable(props) {
    const { token } = props;
    const [moduleData, setModuleData] = useState([]);
    const [moduleFormOpen, setModuleFormOpen] = useState(false);
    const [moduleInfoOpen, setModuleInfoOpen] = useState(false);
    const [selectedModuleForInfo, setSelectedModuleForInfo] = useState(null);
    const [actionType, setActionType] = useState(null); // Variable para almacenar el tipo de acción

    const [selectedModule, setSelectedModule] = useState(null);


    const [isModalOpen, setIsModalOpen] = useState(false);
    
    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleEdit = (module) => {
        setSelectedModule(module);
        setModuleFormOpen(true);
    };
    useEffect(() => {
        reloadModules();
    }, []);
    const reloadModules = () => {
        getAllModules(token)
            .then((response) => {
                const data = response.data;
                setModuleData(data);
            })
            .catch((error) => {
                console.error('Error al obtener los módulos:', error);
            });
    };
    const handleCreateNew = () => {
        setSelectedModule(null); // Restablece el módulo seleccionado para crear uno nuevo
        setModuleFormOpen(true);
    };
    const handleCreateOrUpdate = (response) => {
        reloadModules(); // Vuelve a cargar los módulos después de crear o actualizar uno
        // Aquí puedes realizar otras acciones si lo deseas
    };
    const handleDelete = (moduleId) => {
        deleteModuleById(token, moduleId)
            .then(() => {
                console.log('Módulo eliminado exitosamente.');
                reloadModules(); // Vuelve a cargar los módulos después de eliminar uno
            })
            .catch((error) => {
                console.error('Error al eliminar el módulo:', error);
            });
    };
    const handleConfirmDelete = (module) => {
        setSelectedModuleForInfo(module);
        setActionType('delete'); // Establece el tipo de acción como 'delete'
        setModuleInfoOpen(true);
        return (
            <div>
                <h2>Confirmar Eliminación</h2>
                <p>¿Estás seguro de que deseas eliminar este módulo?</p>
                <button onClick={() => handleDelete(module.id)}>Eliminar</button>
                <button onClick={() => setModuleInfoOpen(false)}>Cancelar</button>
            </div>
        );
    };

    return (

        <div className="pt-8 w-full flex flex-col font-montserrat">
            <div className="px-8 flex justify-between">
                <h2 className="font-medium text-xl min-w-0 whitespace-nowrap overflow-hidden text-ellipsis">
                    Módulos
                </h2>
                <button className="bg-emerald-500 hover:bg-emerald-400 text-sm text-white font-semibold py-2 px-3 rounded-lg  " onClick={handleCreateNew}>
                    Nuevo Módulo
                </button>
            </div>

            <ModulesForm isModalOpen={isModalOpen} onClose={closeModal} token={token} />

            <div className="mt-4 px-6 gap-x-8 gap-y-6 grid grid-cols-1">
                <CardContainer className="px-6 py-4 shadow-md">
                    <div className="mt-2 overflow-x-auto relative sm:rounded-lg">
                        <p className='font-semibold text-lg mb-3'>Gestor de Módulos</p>
                        <table className="w-full">
                            <thead className="bg-neutral-800 text-xs text-white">
                                <tr>
                                    <th className="px-2 py-2">ID</th>
                                    <th className="px-2 py-2">Nombre</th>
                                    <th className="px-2 py-2">Precio (BOB)</th>
                                    <th className="px-2 py-2">Duración (días)</th>
                                    <th className="px-2 py-2">Fecha de Creación</th>
                                    <th className="px-2 py-2">Estado</th>
                                    <th className="px-2 py-2">Acciones</th>
                                </tr>
                            </thead>
                            <tbody className='text-sm border text-center'>
                                {moduleData.map((module) => (
                                    <tr key={module.id}>
                                        <td className=" px-4 py-2">{module.id}</td>
                                        <td className=" px-4 py-2">{module.name}</td>
                                        <td className=" px-4 py-2">{module.price}</td>
                                        <td className=" px-4 py-2">{module.duration}</td>
                                        <td className=" px-4 py-2">{new Date(module.created_at).toLocaleString()}</td>
                                        <td className=" px-4 py-2">{module.status === 1 ? 'Activo' : 'Inactivo'}</td>
                                        <td className=" px-4 py-2">
                                            <div className='flex  justify-center'>
                                                <div className='bg-green-500 hover:bg-green-400 cursor-pointer ml-1 p-2 rounded-md text-white' onClick={() => handleEdit(module)}><FaPen /></div>
                                                <div className='bg-red-500 hover:bg-red-400 cursor-pointer ml-1 p-2 rounded-md text-white' onClick={() => handleConfirmDelete(module)}><FaTrash /></div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <ModulesForm
                            isOpen={moduleFormOpen}
                            onClose={() => setModuleFormOpen(false)}
                            onSubmit={handleCreateOrUpdate}
                            token={token}
                            moduleData={selectedModule}
                            reloadModules={reloadModules}
                        />
                        <ConfirmationModal
                            isOpen={moduleInfoOpen}
                            onClose={() => setModuleInfoOpen(false)}
                            message="¿Estás seguro de que deseas eliminar este módulo?"
                            onConfirm={() => handleDelete(selectedModuleForInfo.id)} // Pasar la función handleDelete como prop
                        />

                    </div>
                </CardContainer>
            </div>
        </div>
    );
}

export { ModulesTable };
