export function LoadingTableData() {
  return (
    <div className="animate-pulse">
      <div className="bg-stone-300 h-5 rounded-md"></div>
    </div>
  );
}

export function LoadingTableRow() {
  return (
    <>
      <tr className="">
        <td className="px-2 py-4">
          <LoadingTableData />
        </td>
        <td className="px-6 py-4">
          <LoadingTableData />
        </td>
        <td className="px-6 py-4">
          <LoadingTableData />
        </td>
        <td className="px-6 py-4">
          <LoadingTableData />
        </td>
        <td className="px-6 py-4 w-1/6">
          <LoadingTableData />
        </td>
        <td className="px-6 py-4"></td>
      </tr>
      <tr className="">
        <td className="px-2 py-4">
          <LoadingTableData />
        </td>
        <td className="px-6 py-4">
          <LoadingTableData />
        </td>
        <td className="px-6 py-4">
          <LoadingTableData />
        </td>
        <td className="px-6 py-4">
          <LoadingTableData />
        </td>
        <td className="px-6 py-4 w-1/6">
          <LoadingTableData />
        </td>
        <td className="px-6 py-4"></td>
      </tr>
    </>
  );
}
