import {
  DialogClose,
  DialogContent,
  DialogOverlay,
  DialogPortal,
} from "@radix-ui/react-dialog";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineClose } from "react-icons/ai";
import { useSelector } from "react-redux";

import LoadingButton from "../../../components/LoadingButton";
import { editAreaForMany } from "../Api/AdminServices";

export function MultipleAreaEditionModal(props) {
  const { setEmployeesAreaBySelected } = props;
  const { employeesSelected } = props;

  const token = useSelector((state) => state.login.token);
  const sizeSelected = employeesSelected.size;

  const { register, handleSubmit } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const closeRef = useRef(null);

  function handleUpdateEmployeeArea(data) {
    data.ids = Array.from(employeesSelected);
    editAreaForMany(data, token)
      .then((response) => {
        setEmployeesAreaBySelected(data.rubro);
        if (closeRef.current) {
          closeRef.current.click();
        }
      })
      .catch((error) => {
        console.error(error);
        window.alert(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  return (
    <DialogPortal>
      <DialogOverlay className="overlay-animation z-40 inset-0 fixed bg-stone-950/25" />
      <DialogContent className="modal-animation z-50 fixed rounded-2xl sm:max-w-screen-sm max-w-[640px] w-full left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-white">
        <div className="px-8 py-4 font-montserrat relative h-full w-full">
          <h2 className="font-medium text-lg">
            Edición de rubro
            <p className="ml-1 text-sm text-stone-600">
              {sizeSelected} integrante{sizeSelected === 1 ? "" : "s"}{" "}
              seleccionado
              {sizeSelected === 1 ? "" : "s"}
            </p>
          </h2>

          <div className="mt-4 h-3/4 text-sm">
            <form onSubmit={handleSubmit(handleUpdateEmployeeArea)}>
              <div>
                <label htmlFor="rubro"> Actualizar el cargo / ocupación</label>
                <input
                  {...register("rubro")}
                  id="rubro"
                  type="text"
                  placeholder="No definido"
                  className="mt-1 px-4 py-2 border outline-none w-full"
                />
              </div>

              <div className="mt-4 flex justify-end">
                <DialogClose asChild>
                  <button
                    type="button"
                    className="px-8 py-1 font-medium w-fit rounded-md border border-stone-300 text-stone-900 bg-stone-100"
                  >
                    Cancelar
                  </button>
                </DialogClose>
                <LoadingButton
                  isLoading={isLoading}
                  className="ml-4 px-8 py-1 font-medium w-fit rounded-md text-stone-100 bg-stone-950"
                >
                  Actualizar
                </LoadingButton>
              </div>
            </form>
          </div>
        </div>

        <DialogClose asChild>
          <button
            ref={closeRef}
            type="button"
            className="top-5 right-5 absolute"
          >
            <AiOutlineClose
              className="p-[6px] rounded-full box-content hover:bg-stone-100"
              size={20}
            />
          </button>
        </DialogClose>
      </DialogContent>
    </DialogPortal>
  );
}
