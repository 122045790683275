import { useEffect, useState } from "react";

import { LOCALHOST_DOMAIN, DOMAIN, ROUTER_INITIAL } from "../../config/Constant";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userLogout } from "../../redux/loginSlice";
import { Sidebar, Option } from "../../components/Sidebar";
import { auth } from "../../firebase/FirebaseUtils";

import EditCard from "./EditCard";
import UserProfile from "./UserProfile";
import UserAnalytics from "./UserAnalytics";
import UserSettings from "./UserSettings";

import TokenRefresher from "../../components/TokenRefresher";
import { OptionAdmin, SidebarAdmin } from "../admin/SidebarAdmin";
import { AdminHome } from "../admin/AdminHome";
import { AdminCompany } from "../admin/AdminEmployees";
import { AdminLicense } from "../admin/AdminLicense";
import { AdminAnalytics } from "../admin/AdminAnalytics";
import { SuperUsers } from "../admin_super/SuperUsers";
import {SuperAdminAnalytics} from "../admin_super/SuperAdminAnalytics";
import { ProductsTable } from "../admin_super/ProductsTable";
import {
  OptionsSuper,
  SidebarAdminSuper,
} from "../admin_super/SidebarSuperAdmin";

import "react-toastify/dist/ReactToastify.css";
import { objectToUserRoleIncorrectOnPurpose, UserRolesEnum } from "../../utils/objectToUserRole";
import { PersonificationMenuBar } from "../../components/PersonificationMenuBar";

import { Discounts} from "../admin_super/Discounts";
import {BookingPage} from '../booking_page/BookingPage';
import { SalesManager } from "../sales_manager/SalesManager";
import {ClientContactPage} from '../client_contact/ClientContactPage';
import {ModulesPage} from '../services/ServicesSuperAdminPage';
import {ModulesClientPage} from '../services/ServicesClientPage';


const MenuPrincipal = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  let [user, setUser] = useState({});
  const token = useSelector((state) => state.login.token);
  let userRole = useSelector((state) => state.login.userRole);
  userRole = Number.parseInt(userRole);
  const [menuOption, setMenuOption] = useState(Option.PROFILE);

  const [userPersonified, setUsersPersonified] = useState(undefined);

  function overrideUser() {
    if (userPersonified) return true;
    return false;
  }
  if (overrideUser()) {
    user = userPersonified;
    userRole = objectToUserRoleIncorrectOnPurpose(userPersonified);
    setUser = setUserPersonified;
  }

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getUser() {
    let response = await fetch(DOMAIN + "auth/user", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${token}`,
      },
    });
    response
      .json()
      .then((data) => {
        if (data.detail === "Invalid Token") {
          dispatch(userLogout(token));
          return history(ROUTER_INITIAL);
        }

        if (data.detail === "Email not Verified") {
          dispatch(userLogout(token));
          return history(ROUTER_INITIAL);
        }
        setUser(data);
      })
      .catch((error) => {
        console.error(error);
        dispatch(userLogout(token));
        return history(ROUTER_INITIAL);
      });
  }

  const logout = () => {
    auth.signOut(user);
    dispatch(userLogout(token));
    history(ROUTER_INITIAL);
  };

  function setUserPersonified(userData) {
    setUsersPersonified(userData);
  }

  function stopPersonification() {
    setMenuOption(0);
    setUsersPersonified(undefined);
  }

  function showAppOptionAdmin(option) {
    switch (option) {
      case OptionAdmin.HOME:
        return <AdminHome />;
      case OptionAdmin.COMPANY:
        return (
          <AdminCompany
            userId={user.id}
            user={user}
            userPersonified={userPersonified}
          />
        );
      case OptionAdmin.ANALYTICS:
        return <AdminAnalytics userPersonified={userPersonified} />;
      case OptionAdmin.LICENCE:
        return <AdminLicense user={user}/>;
      case OptionAdmin.NOTIFICATIONS:
        return <ClientContactPage user={user} token={token}></ClientContactPage>;
      case OptionAdmin.SETTINGS:
        return (
          <UserSettings userData={user} setUserData={setUser} logout={logout} />
        );
      case OptionAdmin.BOOKING:
        return  <BookingPage userData={user}
        userPersonified={userPersonified}/>;
      case OptionAdmin.PAYMENTS:
        return <SalesManager user={user}/>;
      case OptionAdmin.STORE:
        return  <ModulesClientPage userData={user}
        userPersonified={userPersonified}/>;
      default:
        return <> No funciona </>;
    }
  }

  function showAppOptionSuperAdmin(option) {
    switch (option) {
      case OptionsSuper.USERS:
        return <SuperUsers setUserPersonified={setUserPersonified} />;
      case OptionsSuper.ANALYTICS:
        return <SuperAdminAnalytics userId={user.id} />;
      case OptionsSuper.PRODUCTS:
        return  <ProductsTable />;
      case OptionsSuper.PAYMENTS:
        return  <SalesManager />;
      case OptionsSuper.DISCOUNTS:
        return  <Discounts />;
      case OptionsSuper.BOOKING:
        return  <BookingPage userData={user}
        userPersonified={userPersonified}/>;
      case OptionsSuper.STORE:
        return  <ModulesPage userData={user} userPersonified={userPersonified}/>;
      case OptionsSuper.NOTIFICATIONS:
        return <ClientContactPage user={user} userPersonified={userPersonified} token={token}></ClientContactPage>;
      
      case OptionsSuper.SETTINGS:
        return <div> 3 </div>;
      default:
        return <> No funciona </>;
    }
  }

  function showAppOption(option) {
    switch (option) {
      case Option.PROFILE:
        if (user.username === undefined) {
          return <> </>;
        }
        return (
          <UserProfile userData={user} setUserData={setUser}></UserProfile>
        );
      case Option.EDIT_CARD:
        return (
          <EditCard
            userData={user}
            userPersonified={userPersonified}
          ></EditCard>
        );
      case Option.METRICS:
        return <UserAnalytics user={user}></UserAnalytics>;

      case Option.NOTIFICATIONS:
        return <ClientContactPage user={user} token={token}></ClientContactPage>;

        
      case Option.SETTINGS: 
        return (
          <UserSettings
            userData={user}
            setUserData={setUser}
            logout={logout}
          ></UserSettings>
        );
      case Option.BOOKING:
        return  <BookingPage userData={user}
        userPersonified={userPersonified}/>;

      case Option.PAYMENTS:
        return  <SalesManager userData={user}
        userPersonified={userPersonified}/>;
      default:
        return <> No funciona </>;
    }
  }

  function renderSidebarByRole(role) {
    if (role === UserRolesEnum.ADMIN) {
      return (
        <SidebarAdmin
          selected={menuOption}
          setSidebarOption={setMenuOption}
          logout={logout}
          isBooking={user.is_booking}
          userId={user.id}
          isSalesManager={user.is_sales_manager}
          user={user}

        />
      );
    }

    if (role === UserRolesEnum.SUPERADMIN) {
      return (
        <SidebarAdminSuper
          selected={menuOption}
          setSidebarOption={setMenuOption}
          logout={logout}
          
        />
      );
    }
    return (
      <Sidebar
        selected={menuOption}
        setSidebarOption={setMenuOption}
        logout={logout}
        isBooking={user.is_booking}
        isSalesManager={user.is_sales_manager}
        user={user}

      />
    );
  }

  function renderOptionsByRole(role) {
    if (role === UserRolesEnum.ADMIN) {
      return showAppOptionAdmin(menuOption);
    }
    if (role === UserRolesEnum.SUPERADMIN) {
      return showAppOptionSuperAdmin(menuOption);
    }
    return showAppOption(menuOption);
  }

  return (
    <div className="w-full min-h-screen sm:pl-56 bg-[rgb(240,240,240)]">
      <TokenRefresher />
      {renderSidebarByRole(userRole)}

      <PersonificationMenuBar
        stopPersonification={stopPersonification}
        userPersonified={userPersonified}
      />
      <div className="pb-16 sm:pb-0">{renderOptionsByRole(userRole)}</div>
    </div>
  );
};
export default MenuPrincipal;
