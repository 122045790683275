import { ShoppingCardProduct } from "./ShoppingCartProduct";

export function ShoppingCartTable(props) {
  const { products, setProducts } = props;

  if (Object.keys(products).length === 0) {
    return <div>No tienes ningún producto agregado</div>;
  }

  function setQuantity(product_id, quantity) {
    const product = products[product_id];
    if (!product) return;

    product.quantity = quantity;
    setProducts({ ...products, [product_id]: product });
  }

  function deleteProduct(product_id) {
    delete products[product_id];
    setProducts({ ...products });
  }

  return (
    <table className="w-full">
      <thead>
        <tr className="text-sm sm:text-base text-left">
          <th className="font-medium max-w-fit items-center"> Producto </th>
          <th className="font-medium text-center"> Precio </th>
          <th className="font-medium text-center"> Cantidad </th>
          <th className="font-medium text-center"> Total </th>
        </tr>
      </thead>
      <tbody>
        {Object.values(products).map((product, index) => (
          <ShoppingCardProduct
            key={index}
            setQuantity={setQuantity}
            deleteProduct={deleteProduct}
            {...product}
          />
        ))}
      </tbody>
    </table>
  );
}
