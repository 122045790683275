import { useState } from "react";
import { FaTimes, FaTrash, FaRetweet, FaTimesCircle } from 'react-icons/fa';
import { toast } from "react-toastify";
import { deleteUser, resetAccount, deleteLicense } from '../Api/superUserService';
import { useSelector } from "react-redux";


function SimpleDeleteModal({ isOpen, onClose, userId, username, customUser }) {
  const token = useSelector((state) => state.login.token);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationInput, setConfirmationInput] = useState("");
  const [selectedAction, setSelectedAction] = useState(null);
  function handleDeleteConfirmation(action) {
    setSelectedAction(action);
    setShowConfirmation(true);
  }

  async function handleActionConfirmation() {
    const userIdToDelete = userId;
    if (confirmationInput.trim() === "borrar") {
      if (userIdToDelete) {
        // Lógica para ejecutar la acción seleccionada
        switch(selectedAction) {
          case 'deleteAccount':
            await deleteUser(userIdToDelete, token);
            toast.error("Cuenta Eliminada");
            break;
          case 'resetAccount':
            await resetAccount(userIdToDelete, token);
            toast.info("Cuenta Resetada");
            break;
          case 'deleteLicense':
            await deleteLicense(customUser.licencia.id, token)
            // Lógica para eliminar licencia
            toast.warn("Licencia Eliminada");
            break;
          default:
            break;
        }
        setShowConfirmation(false);
        setConfirmationInput("");
      } else {
        console.error("userIdToDelete está undefined");
      }
    }
  }

  function handleCloseConfirmationModal() {
    setShowConfirmation(false);
    setConfirmationInput(""); 
  }

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-neutral-800 bg-opacity-10">
          <div className="bg-white max-w-max p-6 rounded-md shadow-lg">
            <div className="flex justify-between items-center mb-4">
              <p className="text-base font-semibold">Eliminar</p>
              <FaTimes onClick={onClose} className="text-neutral-800 cursor-pointer w-4 h-4" />
            </div>
            <p className="text-gray-700 mb-4">¿Qué acción desea realizar?</p>

            <div className="flex">
              <button 
                onClick={() => handleDeleteConfirmation('deleteAccount')}
                className={`flex items-center justify-center bg-red-500 text-white py-2 px-4 rounded-lg ${
                  customUser?.is_superuser ? "bg-gray-300" : "bg-red-500 hover:bg-red-600"
                }`}
                disabled={customUser?.is_superuser}
              >
                <FaTrash className="mr-2" /> Eliminar Cuenta
              </button>
              <button
                onClick={() => handleDeleteConfirmation('resetAccount')}
                className="flex items-center justify-center bg-neutral-800 text-white py-2 px-4 rounded-lg ml-2"
              >
                <FaRetweet className="mr-2" /> Resetear Cuenta
              </button>
              <button
                onClick={() => handleDeleteConfirmation('deleteLicense')}
                className="flex items-center justify-center bg-orange-500 text-white py-2 px-4 rounded-lg ml-2"
              >
                <FaTimesCircle className="mr-2" /> Eliminar Licencia
              </button>
            </div>
          </div>
        </div>
      )}

      {showConfirmation && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-stone-950/60">
          <div className="bg-white rounded-lg p-8">
            <p className="mb-4">¿Está seguro que desea realizar esta acción?</p>
            <p className="mb-2 text-xs text-neutral-400">Escriba en el siguiente campo la palabra "borrar" para continuar</p>
            <input
              type="text"
              placeholder="Digite (borrar) "
              className="border py-2 w-full p-2 mb-4"
              value={confirmationInput}
              onChange={(e) => setConfirmationInput(e.target.value)}
            ></input>
            <div className="flex justify-center">
              <div
                onClick={handleActionConfirmation}
                className={`cursor-pointer text-center px-4 py-2 mr-4 ${
                  confirmationInput.trim() === "borrar"
                    ? "bg-red-500 text-white rounded-lg"
                    : "bg-gray-300 text-gray-800 rounded-lg cursor-not-allowed"
                }`}
                disabled={confirmationInput.trim() !== "borrar"}
              >
                Confirmar
              </div>
              <div
                onClick={handleCloseConfirmationModal}
                className="cursor-pointer text-center px-4 py-2 bg-gray-300 text-gray-800 rounded-lg"
              >
                Cancelar
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export { SimpleDeleteModal };
