import React from 'react';
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';

Modal.setAppElement('#root');

const ConfirmationProductModal = ({ isOpen, onRequestClose, onConfirm, onCancel, message }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Modal de Confirmación"
      className="Modal fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-md p-6 max-w-md"
      overlayClassName="Overlay fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-medium">Confirmar Acción</h2>
        <button
          onClick={onRequestClose}
          className="text-gray-500 hover:text-gray-700 focus:outline-none"
        >
          <FaTimes />
        </button>
      </div>
      <p className="mb-4">{message}</p>
      <div className="flex justify-end">
        <button
          onClick={() => {
            onConfirm();
            onRequestClose();
          }}
          className="bg-red-500 py-2 px-4 rounded-lg text-white mr-2 hover:bg-red-600"
        >
          Confirmar
        </button>
        <button
          onClick={() => {
            onCancel();
            onRequestClose();
          }}
          className="text-white py-2 px-4 rounded-lg bg-neutral-800 hover:bg-neutral-700"
        >
          Cancelar
        </button>
      </div>
    </Modal>
  );
};

export { ConfirmationProductModal };
