import React from "react";

import OverlayModal from "../../../../components/Modals/OverlayModal";

const BaseModal = (props) => {
  const { setShowModal, showModal, children } = props;

  function closeModal() {
    setShowModal(false);
  }

  function renderChildren() {
    return React.cloneElement(children, {
      setShowModal: setShowModal,
    });
  }

  return showModal ? (
    <>
      <OverlayModal onClick={closeModal} />

      <div
        className={`fixed right-1/2 top-1/2 -translate-y-1/2 translate-x-1/2 px-7 pt-4 pb-4 z-50 border rounded-md border-stone-300 bg-white shadow-lg`}
      >
        {renderChildren()}
      </div>
    </>
  ) : (
    <></>
  );
};
export default BaseModal;
