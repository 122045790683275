import { ShoppingCartTable } from "./ShoppingCartTable";


export function ShoppingCart(props) {
  const { products, setProducts } = props;

  return (
    <div className="flex flex-col gap-4">


      <div className="min-h-[8rem] overflow-y-auto">
        <ShoppingCartTable products={products} setProducts={setProducts} />
      </div>

    </div>
  );
}
