import React from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const formatHour = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
  const formattedMins = mins < 10 ? `0${mins}` : `${mins}`;
  return `${formattedHours}:${formattedMins}`;
};

const TimeRangeSlider = ({ label, value, onChange, min, max,sliderColor  }) => {
  const sliderTrackStyle = {
    height: '8px', 
    backgroundColor: sliderColor || '#000', 
    
  };

  const railStyle = {
    backgroundColor: 'rgba(209, 209, 209, 0.5)', // Color del riel cuando está vacío
    height: '3px',
  };

  return (
    <div className="mb-1">
      <div className="flex flex-col items-center">
        <div className="p-1 flex justify-between w-full">
          <span className="text-sm font-semibold text-neutral-200 opacity-50">{formatHour(min)}</span>
          <span className="text-sm font-semibold text-neutral-200 opacity-50">{formatHour(max)}</span>
        </div>
        <Slider
          className="w-full"
          value={value}
          onChange={onChange}
          min={min}
          max={max}
          range
          step={5} // Puedes ajustar el paso según tus necesidades
          trackStyle={sliderTrackStyle} 
          railStyle={railStyle}
        />
        <div className="flex justify-between w-full p-1">
          <span className="text-base font-bold text-neutral-200">{formatHour(value[0])}</span>
          <span className="text-base font-bold text-neutral-200">{formatHour(value[1])}</span>
        </div>
      </div>
    </div>
  );
};

export default TimeRangeSlider;
