export function formatDate(date) {
  let stringDate = date.toISOString().slice(0, 10);

  let [year, month, day] = stringDate.split("-");
  return `${day}/${month}/${year}`;
}

export function formatDateForInput(date) {
    let stringDate = date.toISOString().slice(0,10);
    return stringDate
}

export function addDurationToDate(date, days) {
  let copy = new Date(date);
  copy.setDate(copy.getDate() + days);
  return copy;
}
