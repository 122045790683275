
export function removeCountryCode(phone) {
    const countryCodes = new Set([
      "+1",
      "+20",
      "+30",
      "+32",
      "+33",
      "+36",
      "+39",
      "+43",
      "+45",
      "+49",
      "+51",
      "+52",
      "+54",
      "+55",
      "+56",
      "+57",
      "+58",
      "+60",
      "+61",
      "+62",
      "+81",
      "+86",
      "+91",
      "+93",
      "+98",
      "+212",
      "+213",
      "+244",
      "+251",
      "+254",
      "+264",
      "+352",
      "+353",
      "+355",
      "+357",
      "+358",
      "+359",
      "+372",
      "+375",
      "+376",
      "+377",
      "+385",
      "+420",
      "+502",
      "+504",
      "+505",
      "+506",
      "+591",
      "+593",
      "+598",
      "+595",
      "+855",
      "+880",
      "+961",
      "+965",
      "+973",
    ]);
  
    let justPhone = phone;
    let countryCode = "";
    for (let item of countryCodes) {
      if (phone.startsWith(item)) {
        justPhone = phone.substr(item.length);
        countryCode = item;
      }
    }
    return { justPhone, countryCode };
  }
  