
import CardContainer from "../../components/CardContainer"

export function AdminHome() {

    return <div className="pt-8 w-full flex flex-col font-montserrat">
    <div className="px-8 flex justify-between">
      <h2 className="font-medium text-xl min-w-0 whitespace-nowrap overflow-hidden text-ellipsis">
        Empresa
      </h2>
    </div>
    <div className="mt-4 px-6 gap-x-8 gap-y-6 grid grid-cols-1 lg:grid-cols-2">
      <CardContainer className="px-6 py-4 shadow-md">
        <h2 className="font-medium"> Inicio </h2>
      </CardContainer>
    </div>
  </div>
}