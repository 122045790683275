
import CardContainer from "../../components/CardContainer";
import React, { useState } from 'react';
import ModulesForm from './components/ServicesForm';
import { useSelector } from "react-redux";
import {ModulesTable} from './components/ServicesTable';

function ModulesPage() {
  const token = useSelector((state) => state.login.token);
  
  

  return (
    <>
      
              <ModulesTable token={token} />
    </>
  );
}

export { ModulesPage }
