import React, { useRef } from "react";

import { CgColorPicker } from "react-icons/cg";

const EditColorButton = (props) => {
  const colorRef = useRef(null);

  const { registerForm, handleUpdateColorUI } = props;
  const { ref, ...rest } = registerForm("color", {
    onChange: (event) => {
      handleUpdateColorUI(event);
    },
  });

  function handleColorPicker() {
    if (colorRef.current) {
      colorRef.current.click();
    }
  }

  return (
    <div className="flex flex-wrap items-center gap-4 sm:gap-6">
      <div
        onClick={handleColorPicker}
        className="p-2 cursor-pointer rounded-full w-fit bg-stone-200"
      >
        <CgColorPicker size={16} />
      </div>
      <input
        id="color"
        name="color"
        {...rest}
        className="hidden"
        ref={(e) => {
          ref(e);
          colorRef.current = e;
        }}
        type="color"
      />

      <div
        onClick={() => handleUpdateColorUI(undefined, "#EF4444")}
        className="h-6 w-6 rounded-full cursor-pointer bg-red-500"
      ></div>
      <div
        onClick={() => handleUpdateColorUI(undefined, "#F97316")}
        className="h-6 w-6 rounded-full cursor-pointer bg-orange-500"
      ></div>
      <div
        onClick={() => handleUpdateColorUI(undefined, "#FACC15")}
        className="h-6 w-6 rounded-full cursor-pointer bg-yellow-400"
      ></div>
      <div
        onClick={() => handleUpdateColorUI(undefined, "#2563EB")}
        className="h-6 w-6 rounded-full cursor-pointer bg-blue-600"
      ></div>
      <div
        onClick={() => handleUpdateColorUI(undefined, "#16A34A")}
        className="h-6 w-6 rounded-full cursor-pointer bg-green-600"
      ></div>
      <div
        onClick={() => handleUpdateColorUI(undefined, "#A855F7")}
        className="h-6 w-6 rounded-full cursor-pointer bg-purple-500"
      ></div>
    </div>
  );
};
export default EditColorButton;
