export function getUserInitials(name, username) {
  let source = name ? name : username;
  if(!source) {
    source = "Anónimo"
  }
  let a = source.split(" ");

  if (a.length === 1) {
    a = source.split(".");
  }
  if (a.length === 1) {
    a = source.split("-");
  }
  if (a.length === 1) {
    a = source.split("_");
  }
  if (a.length === 1) {
    a = source.split("");
  }

  let initials = `${a[0][0]}${a[1] ? a[1][0] : ""}`.toUpperCase();
  return initials;
}
