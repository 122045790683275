// bookingSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pendingCount: 0,
  acceptedCount: 0,
  canceledCount: 0,
  inProgressCount: 0,
  completedCount: 0,
};

const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    setCount: (state, action) => {
      const { status, count } = action.payload;
      state[`${status}Count`] = count;
    },
  },
});

export const { setCount } = bookingSlice.actions;
export default bookingSlice.reducer;
