export function FrequentQuestion(props) {
  const { question, answer } = props;

  return (
    <div className="max-w-2xl flex flex-col"> 
      <h2 className="md:text-xl font-semibold"> {question}</h2>
      <p className="mt-1 tracking-wide"> {answer} </p>
    </div>
  );
}
