import React from "react";

function UserProfileLog({ profiles }) {
  return (
    <div className="mt-2 max-h-80 overflow-y-auto">
      <table className="min-w-full divide-y divide-gray-200 ml-4">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Registro de Fecha
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Contador
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200 text-between  ">
          {profiles.map((profile, index) => (
            <tr key={index}>
              <td className="">{profile.timestamp}</td>
              <td className="">{profile.counter}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default UserProfileLog;
