import React from 'react';
import jsPDF from 'jspdf';

const Reporte = (selectedTransaction) => {
const data=selectedTransaction.transaction

if (!data) {
  return <div>No hay datos seleccionados.</div>;
}

  const generarPDF = () => {
    const doc = new jsPDF();
    
  
    let yPos = 12 ; 
    doc.setFont('helvetica');
    doc.setFontSize(10);
    
    yPos += 6;
    doc.setFont('helvetica', 'bold'); 
    doc.text('Reporte de ventas', 10, 10);
    doc.setFont('helvetica', 'normal'); 
    doc.text(`Nº: ${data.id}`, 10, yPos);
    yPos += 6;
    doc.text(`Fecha: ${data.date}`, 10, yPos);
    yPos += 6;
    doc.text(`Código de Transacción: ${data.codigoTransaccion}`, 10, yPos);
    yPos += 12;

    doc.setFont('helvetica', 'bold'); 
    doc.text(`Comprador:`, 10, yPos);
    doc.text(`Documento:`, 10, yPos + 6);
    doc.text(`Correo:`, 10, yPos + 12);
    doc.text(`Teléfono:`, 10, yPos + 18);
    doc.text(`Dirección:`, 10, yPos + 24);
    doc.setFont('helvetica', 'normal'); 
    doc.text(`${data.nombreComprador} ${data.apellidoComprador}`, 35, yPos);
    doc.text(`${data.documentoComprador}`, 35, yPos + 6);
    doc.text(`${data.customer_user_email}`, 30, yPos + 12);
    doc.text(`${data.customer_user_phone_number}`, 35, yPos + 18);
    doc.text(`${data.direccionComprador}, ${data.ciudad}`, 35, yPos + 24);
    yPos += 36;
  
doc.setFont('helvetica', 'bold'); 
doc.text(`Descripción: ${data.descripcion}`, 10, yPos);
yPos += 6;
doc.text('Detalle:', 10, yPos);
doc.setFont('helvetica', 'normal'); 
yPos += 6;

/* venta.detalle.forEach((producto, idx) => {
  doc.text(`- ${producto.nombre}`, 15, yPos);
  yPos += 6;
  doc.text(`  Cantidad: ${producto.cantidad}`, 15, yPos);
  yPos += 6;
  doc.text(`  Precio: ${producto.precio}`, 15, yPos);
  yPos += 6;
}); */

yPos += 6;

  
if (data.discount_value) {
  doc.setFont('helvetica', 'bold'); 
  doc.text(`Descuento:`, 10, yPos);
  doc.setFont('helvetica', 'normal');
  doc.text(`${data.discount_value * 100}%`, 35, yPos);
  yPos += 6;
}

doc.setFont('helvetica', 'bold'); 
doc.text(`Monto:`, 10, yPos);
doc.setFont('helvetica', 'normal'); 
doc.text(`${data.monto} ${data.moneda}`, 25, yPos);
yPos += 12;
    yPos += 6;
    doc.line(10, yPos, 200, yPos);
    doc.save('reporte_ventas.pdf'); 
  };

  const textGray600 = 'text-neutral-800 bg-white text-sm ';

  return (
      <div className="  p-4 sm:w-full lg:w-full bg-white rounded-md shadow-lg border border-neutral-200">
        <button onClick={generarPDF} className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">Generar PDF</button>
          <div className=" p-2 rounded-lg">
            
            <div className="grid grid-cols-2 gap-4">
              <div className="flex items-center">
                <p className={`${textGray600} mr-2`}>Nº:</p>
                <h5 className={`${textGray600} font-bold`}>{data.id}</h5>
              </div>
              <div>
                <p className={`${textGray600}`}><strong>Fecha:</strong>  {data.date}</p>
                <p className={`${textGray600}`}><strong>Código de Transacción:</strong> {data.codigoTransaccion}</p>
                
              </div>
            </div>
            <hr className="my-2" />
            <div>
            <div className="grid grid-cols-2 gap-4">
              <p className={`${textGray600}`}><strong>Comprador:</strong> {data.nombreComprador} {data.apellidoComprador}</p>
              <p className={`${textGray600}`}><strong>Documento:</strong> {data.documentoComprador}</p>
            </div>

            <hr className="my-2" />

              <p className={`${textGray600}`}><strong>Correo:</strong> {data.customer_user_email}</p>
              <p className={`${textGray600}`}><strong>Teléfono:</strong> {data.customer_user_phone_number}</p>
              <p className={`${textGray600}`}><strong>Dirección:</strong> {data.direccionComprador}, {data.ciudad}</p>
            </div>
            <hr className="my-2" />
            <div>
              <p className={`${textGray600}`}><strong>Descripción:</strong> {data.descripcion}</p>

            <hr className="my-2" />

              {/* <div className="">
                <strong className={`${textGray600} mb-2`}>Detalle:</strong>
                {venta.detalle.map((producto, idx) => (
                  <div key={idx} className="ml-4 p-2 rounded-md text-sm bg-neutral-100">
                    <p className="text-gray-800 font-semibold">{producto.nombre}</p>
                    <p className={`${textGray600}`}>Cantidad: {producto.cantidad}</p>
                    <p className={`${textGray600}`}>Precio: {producto.precio}</p>
                  </div>
                ))}
              </div> */}

            <hr className="my-2" />

              {data.discount_value && (
                <p className={`${textGray600}`}><strong>Descuento:</strong> {data.discount_value} {data.moneda}</p>
              )}
              <p className={`${textGray600}`}><strong>Monto:</strong> {data.monto} {data.moneda}</p>
            </div>
            <hr className="my-2" />

            {/* {data.id_transaccion && (
              <p className={`${textGray600}`}><strong>URL:</strong> <a href={data.id_transaccion} target="_blank" rel="noopener noreferrer">{data.id_transaccion}</a></p>
            )} */}
          </div>
      </div>
  );
};

export default Reporte;
