import axios from 'axios';
import { LOCALHOST_DOMAIN } from "../../../config/Constant";

// Función para crear un nuevo módulo
export const createNewModule = async (data, token) => {
  try {
    const response = await axios.post(`${LOCALHOST_DOMAIN}services/get/`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `JWT ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Función para obtener un módulo por su ID
export const getModuleById = (token, moduleId) => {
  return axios.get(`${LOCALHOST_DOMAIN}services/get/${moduleId}/`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  });
};

// Función para actualizar un módulo por su ID
export const updateModuleById = (token, moduleId, updatedData) => {
  return axios.put(`${LOCALHOST_DOMAIN}services/get/${moduleId}/`, updatedData, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  });
};

// Función para obtener todos los módulos
export const getAllModules = (token) => {
  return axios.get(`${LOCALHOST_DOMAIN}services/get/`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  });
};

// Función para eliminar un módulo por su ID
export const deleteModuleById = (token, moduleId) => {
  return axios.delete(`${LOCALHOST_DOMAIN}services/get/${moduleId}/`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  });
};
