import React, { useState } from "react";

import AddSocialMediaModal from "./AddSocialMediaModal";

const AddSocialMediaButton = (props) => {
  const { icon, title, addSocialMedia, urlPlaceholder } = props;
  let { onSubmit, onComplete } = props;
  const [openEditorModal, setOpenEditorModal] = useState(false);


  if(addSocialMedia) {
    console.warn("'addSocialMedia' no debería ser utilizado, reemplazar por 'onComplete'!")
  }

  if(!onComplete) {
    console.warn("'onComplete' no debería ser nulo, envíar parámetro!")
  }

  if(!onSubmit) {
    console.warn("'onSubmit' no debería ser nulo, envíar parámetro!")
  }

  function openEditor() {
    setOpenEditorModal(true);
  }

  return (
    <>
      <button
        className="py-2 flex rounded-md flex-col justify-center items-center cursor-pointer transition-shadow bg-[rgb(245,245,245)] hover:shadow"
        onClick={openEditor}
      >
        <img className="max-w-[2.5rem]" src={icon} alt={`Icono de ${title}`} />
        <p className="px-3 mt-1 text-sm"> {title} </p>
      </button>
      {openEditorModal && (
        <AddSocialMediaModal
          disableImageUpload={true}
          defaultImageSource={icon}
          setShowModal={setOpenEditorModal}
          addSocialMedia={addSocialMedia}
          titleType={`de ${title}`}
          titlePlaceholder={title}
          urlPlaceholder={urlPlaceholder}
          onComplete={onComplete}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
};
export default AddSocialMediaButton;
